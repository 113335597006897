import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';
import { AuthProvider } from './AuthContext';
import { ColorModeProvider } from './ColorModeContext';
import { LoadingProvider } from './LoadingContext';
import { configVars } from '../config';
import { ErrorProvider } from './ErrorContext';

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const appConfig = configVars;
    
    const [app, setApp] = useState(null);

    useEffect(() => {
        setApp(initializeApp(appConfig));
    }, []);

    return (
        <ErrorProvider>
            <AppContext.Provider value={app}>
                <ColorModeProvider>
                    <LoadingProvider>
                        {app && <AuthProvider>{children}</AuthProvider>}
                    </LoadingProvider>
                </ColorModeProvider>
            </AppContext.Provider>
        </ErrorProvider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node,
};

export const useApp = () => useContext(AppContext);
