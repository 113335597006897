// React
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as UsersWIP } from '../../../assets/images/placeholders/UsersWIP.svg';
import { useAuth } from 'contexts/AuthContext';
// Styles
import { Box, styled, Paper, Grid, Typography, useTheme, Button } from '@mui/material';

const Users = () => {
    const theme = useTheme();

    useEffect(() => {
    }, []);

    return (
        <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
            <UsersWIP width={'100%'} height={'100%'}/>
        </Grid>
    );
};

export default Users;