import React from 'react';
import Lottie from 'lottie-react-web';
import PropTypes from 'prop-types';

import styles from './styles';

import animation from './lottie.json';

const Loading = ({ loading }) => {

    return (
        <div style={{ ...styles.loading, opacity: loading ? 1 : 0 }}>
            <Lottie
                options={{
                    animationData: animation,
                    loop: true,
                }
                }
                width={200}
            />
        </div >
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
};

export default Loading;