import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useApp } from './AppContext';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { apiRodx } from '../config';

export const AuthContext = createContext({user: null});

export const AuthProvider = ({children}) => {
    const app = useApp();
    const auth = getAuth(app);
    const [user, setUser] = useState();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) =>{
            setUser(user);
            console.log(user);
        });
        return unsubscribe;
    }, []);
    
    // auth.onAuthStateChanged(currentUser => {
    //     console.log('auth stated changed');
    //     setUser(currentUser);
    // });

    const value = useMemo(() => {
        const login = async (email, password) => {
            const auth = getAuth(app);
            const { user: currentUser } = await signInWithEmailAndPassword(auth, email, password);
            setUser(currentUser);
        };

        const logout = async () => {
            const auth = getAuth(app);
            await auth.signOut();
            setUser(null);
        };

        return {
            login,
            logout,
            user,
        };
    }, [user, app]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => useContext(AuthContext);
