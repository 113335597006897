/* eslint-disable no-constant-condition */
// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Local
import { useNewJob } from 'services/reducers/newJob';
import ModalForm from './ModalForm';
import { useError } from 'contexts/ErrorContext';
import { gatherCatalog } from 'services/utils/enums';
// Styles
import {
    Backdrop,
    Box,
    Button,
    Fade,
    Grid,
    Modal,
    Typography,
    useTheme,
} from '@mui/material';

const NewServiceModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const newJob = useNewJob();
    const err = useError();
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        backgroundColor: theme.palette.grey[30],
        border: '0',
        borderRadius: '16px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
        p: 1,
    };

    const validateData = async function () {
        const catalog = await gatherCatalog();
        let serviceIDs = [];
        if (catalog && catalog.data.status === 200 && catalog.data.payload) {
            let types, repairs, parts;
            types = catalog.data.payload.filter(el => el.service_detail.service_type === newJob.state.service.type);
            if (types.length) {
                if (types.length > 1) {
                    repairs = types.filter(el => (el.service_detail.repair_type === newJob.state.service.repair || el.service_detail.repair_type === 'N/A' || el.service_detail.repair_type === null) );
                    if (repairs.length && repairs.length > 1) {
                        if (newJob.state.service.parts.length) {
                            newJob.state.service.parts.forEach(sEl => {
                                parts = repairs.filter(el => el.service_detail.part === sEl.part && el.service_detail.position === sEl.position );
                                serviceIDs.push(...parts.map(el => el.service_detail.service_detail_id));
                            });
                        }
                    } else if (repairs.length && repairs.length === 1) {
                        serviceIDs.push(...repairs.map(el => el.service_detail.service_detail_id));
                    }
                } else serviceIDs.push(...types.map(el => el.service_detail.service_detail_id));
            } 
        }
        const serviceInCatalog = Boolean(serviceIDs.length);
        const repaiererChosen = (newJob.state.service.repairer.id) ? true : false;
        if (serviceInCatalog && repaiererChosen) {
            return serviceIDs.join(',');
        } else {
            return false;
        }
    };

    const submitService = async function() {
        const j = newJob.state.services.sort((a,b) => a.id - b.id);
        let nextID = (j.length)
            ? newJob.state.services[j.length-1].id + 1
            : 1;
        const validIds = await validateData();
        if (validIds && validIds.length) {
            await newJob.DISPATCH({type: 'NEW_SERVICE', payload: {...newJob.state.service, id: nextID, ids: validIds}});
            err.DISPATCH({type: 'NO_ERROR', payload: {alert: 'Valid Input!'}});
            await handleClose();
        } else err.DISPATCH({type: 'INVALID_INPUT', payload: {alert: 'invalid input'}});
    };

    const handleClose = async function () {
        // @ts-ignore
        await newJob.DISPATCH({type: 'SET_EDIT_ID', payload: 0});
        // @ts-ignore
        await newJob.DISPATCH({type: 'RESET_SERVICE'});
        setOpen(false);
        // TODO Display Error
    };
    
    useEffect(() => {
    }, [open, newJob.state, newJob.state.service]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 300,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid item>
                        <Grid item display={'flex'} justifyContent={'center'} my={3}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 20}}>
                    Add Service
                            </Typography>
                        </Grid>
                        <ModalForm />
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Button
                                onClick={submitService}
                                variant='contained'
                                sx={{backgroundColor: theme.palette.blue[700], color: theme.palette.white, p: 2, width: '85%', textTransform: 'none'}}
                            >
                Done
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};

NewServiceModal.propTypes = {
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default NewServiceModal;
