/* eslint-disable camelcase */
import { STATUSES } from 'services/utils/enums';

export const INIT = function () {
    return INITIAL_STATE;
};

export const INITIAL_STATE = {
    searchTerm: '',
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    locations: [
        {
            id: 1,
            name: 'Austin',
        },
        {
            id: 2,
            name: 'Dallas',
        },
        {
            id: 3,
            name: 'Ft. Lauderdale',
        },
        {
            id: 4,
            name: 'Phoenix',
        },
    ],
    selectedLocations: [],
    repairers: [
        {
            id: 1,
            name: 'Jimmy',
        },
        {
            id: 2,
            name: 'Joe',
        },
        {
            id: 3,
            name: 'Bob',
        },
    ],
    selectedRepairers: [],
    statuses: STATUSES,
    selectedStatuses: [],
    supplements: [
        {
            id: 1,
            name: 'Supplement Approved',
        },
        {
            id: 2,
            name: 'Supplement Pending',
        },
        {
            id: 3,
            name: 'Rework Approved',
        },
        {
            id: 4,
            name: 'Rework Pending',
        },
    ],
    services: [
        {
            id: 1,
            name: 'Bodywork',
        },
        {
            id: 2,
            name: 'Calibration',
        },
        {
            id: 3,
            name: 'De-Identification',
        },
        {
            id: 4,
            name: 'Detail',
        },
        {
            id: 5,
            name: 'Diagnostic',
        },
        {
            id: 6,
            name: 'Glass Repair',
        },
        {
            id: 7,
            name: 'Glass Replacement',
        },
        {
            id: 8,
            name: 'Inspection',
        },
        {
            id: 9,
            name: 'Interior Repair',
        },
        {
            id: 10,
            name: 'Key Replacement',
        },
        {
            id: 11,
            name: 'Mechanical',
        },
        {
            id: 12,
            name: 'Paint',
        },
        {
            id: 13,
            name: 'PDR',
        },
        {
            id: 14,
            name: 'PII Removal',
        },
        {
            id: 15,
            name: 'PPF',
        },
        {
            id: 16,
            name: 'Remove and Install',
        },
        {
            id: 17,
            name: 'Remove and Replace',
        },
        {
            id: 18,
            name: 'Rim Repair',
        },
        {
            id: 19,
            name: 'Tint',
        },
        {
            id: 20,
            name: 'Vinyl Wrap',
        },
    ],
    selectedServices: [],
    sort: [
        {
            id: 1,
            name: 'Newest',
        },
        {
            id: 2,
            name: 'Oldest',
        },
    ],
    filterParams: '',
    selectedSort: [],
    jobs:[],
    activeJob: 0,
    job: {},
    repairOrders:[],
    activeRepairOrder: 0,
    repairOrder: {},
};

export const ACTIONS = {
    SET_SEARCH_TERM: 'set_search',
    RESET_SEARCH: 'reset_search',
    SET_START_DATE: 'set_start_date',
    SET_END_DATE: 'set_end_date',
    LOAD_LOCATIONS: 'load_locations',
    SELECT_LOCATIONS: 'select_locations',
    GET_ROS: 'get_repairOrders',
    GET_JOBS: 'get_jobs',
    GET_JOB: 'get_job',
    SET_ACTIVE_CARD: 'set_active_card',
    SET_FILTER_PARAMS: 'set_filter_params',
    RESET: 'reset',
};

export const JobReducer = function (state, action) {
    // console.log(JSON.stringify(action, null, 2));
    switch (action.type) {
        case ACTIONS.SET_FILTER_PARAMS: {
            return {...state};
            // WORK IN PROGRESS, TODO
            // let { locations, repairers, service_statuses, service_types, start_date, end_date, exact_match_only} = action.payload;
            // let filterParams = [];
            // for (const key in action.payload) {
            //     // TODO
            //     // Split the string on & symbol and then rebuild it
            //     if (action.payload[key].length) {
            //         const element = action.payload[key];
            //         // take out the key
            //         filterParams = filterParams.filter(el => !el.includes(key));
            //     }
            // }
            // // if ( locations || repairers || service_statuses || service_types || start_date || end_date || exact_match_only ) {
            // //     filterParams = `?${locations.join(', ')}`;
            // // }
            // return {...state, filterParams: `?${filterParams.join('&')}`};
        }
        case ACTIONS.SET_SEARCH_TERM:
            return {...state, searchTerm: action.payload};
        case ACTIONS.SET_START_DATE:
            return {...state, startDate: action.payload};
        case ACTIONS.SET_END_DATE:
            return {...state, startDate: action.payload};
        case ACTIONS.LOAD_LOCATIONS:
            return {...state, locations: action.payload};
        case ACTIONS.SELECT_LOCATIONS:
            return {...state, selectedLocations: action.payload};
        case ACTIONS.GET_JOBS:
            return {...state, jobs: action.payload};
        case ACTIONS.GET_ROS:
            return {...state, repairOrders: action.payload};
        case ACTIONS.SET_ACTIVE_CARD:
            return {...state, activeJob: action.payload};
        case ACTIONS.GET_JOB:
            return {...state, job: action.payload};
        case ACTIONS.RESET:
            return INIT();
        default:
            return {...state};
    }
};