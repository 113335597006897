import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';
import axios from 'axios';

export const STATUSES = [
    { 
        id: 0,
        name: 'OPEN',
        status: 'open',
    },
    { 
        id: 1,
        name: 'ACCEPTED',
        status: 'accepted',
    },
    { 
        id: 2,
        name: 'PENDING_APPROVAL',
        status: 'pending approval',
    },
    { 
        id: 3,
        name: 'DECLINED',
        status: 'declined',
    },
    { 
        id: 4,
        name: 'IN_PROGRESS',
        status: 'in progress',
    },
    { 
        id: 5,
        name: 'COMPLETED',
        status: 'completed',
    },
    { 
        id: 6,
        name: 'INVOICED',
        status: 'invoiced',
    },
    { 
        id: 7,
        name: 'CLOSED',
        status: 'closed',
    },
    { 
        id: 8,
        name: 'CANCELLED',
        status: 'cancelled',
    },
];

export const STATUS_BAR_CLASS = {
    'Open': 'OPEN',
    'Pending': 'PENDING',
    'Declined': 'DECLINED',
    'Pending Approval': 'PENDING',
    'In Progress': 'IN_PROGRESS',
    'Accepted': 'ACCEPTED',
    'Cancelled': 'CANCELLED',
    'Completed': 'COMPLETED',
    'Closed': 'CLOSED',
    'Invoiced': 'INVOICED',
};
export const STATUS_BAR_DISPLAY = {
    'Open': 'Open',
    'Pending': 'Pending',
    'Declined': 'Declined',
    'Pending Approval': 'Pending',
    'In Progress': 'In Progress',
    'Accepted': 'Accepted',
    'Cancelled': 'Cancelled',
    'Completed': 'Completed',
    'Closed': 'Closed',
    'Invoiced': 'Invoiced',
};

export const getColors = async function(params) {
    const result = [
        'Beige',
        'Black',
        'Blue',
        'Brown',
        'Gold',
        'Gray',
        'Green',
        'Orange',
        'Purple',
        'Red',
        'Silver',
        'White',
        'Yellow',
    ];
    return result;
};

export const gatherStaticData = async function(params) {
    let config = params;
    const result = await apiRodx['get']('enums');
    return result;
};

export const gatherCatalog = async function(params) {
    let config = params;
    const result = await apiRodx['get']('catalog');
    return result;
};

export const getCatalogVersion = async function(params) {
    let config = params;
    const result = await apiRodx['get']('catalog/version');
    return result;
};
