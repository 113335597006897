// React
import React, { useEffect } from 'react';
// Local
import { apiRodx } from '../../../config';
// Style
import { Grid, Typography, useTheme, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const Developers = () => {
    const theme = useTheme();

    useEffect(() => {
    }, []);

    const handleChange = (event) => {
        apiRodx.defaults.headers.common.Authorization = event.target.value;
    };

    return (
        <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
            <Typography variant={'h3'} color={theme.palette.blue[500]}> Developer Portal </Typography>
            <FormControl sx={{my: 4, p: 2}}>
                <FormLabel id='demo-radio-buttons-group-label'> Choose your Supplier! </FormLabel>
                <RadioGroup
                    name='radio-buttons-group'
                    onChange={handleChange}
                >
                    <FormControlLabel value='1824346de77f72ab9ded64321f6736735252cc6b5bd3dacdbd823c16f333fc2a' control={<Radio />} label={'Joe\'s Garage'} />
                    <FormControlLabel value='ff821b9c56501e5ee8bb02f34c9de5c171f2d7158a4ec8c49ba1b3bddc7bbaca' control={<Radio />} label={'Fitzpatrick\'s Auctioneering Services'} />
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};

export default Developers;