// React
import React, { useState, useEffect } from 'react';
// Local
import NewServiceModal from './NewServiceModal';
import { getLocations } from 'services/actions/location';
import { getColors } from 'services/utils/enums';
import { useNewJob } from 'services/reducers/newJob';
// Styles
import {
    Autocomplete,
    Card,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Paper,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';

const NewJobForm = () => {
    const theme = useTheme();
    const newJob = useNewJob();
    const [locationOptions, setLocationOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
    const [open, setOpen] = useState(false);

    const gatherOptions = async function() {
        const locals = await getLocations();
        const colors = await getColors();

        if (locals && colors) {
            setLocationOptions(locals.data.payload);
            setColorOptions(colors);
        }
    };

    const formInput = function(formType, id, onChange, label, stateVar, options, getOptionLabel, disabled) {
        if (formType === 0) {
            return (
                <Grid item display='flex'  justifyContent={'center'} p={0.5} my={1}>
                    <FormControl sx={{m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <Autocomplete
                            id={id}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            sx={{
                                mt: 1,
                                pl: 1,
                                borderRadius: 0, 
                                border: 0,
                                borderBottomColor: theme.palette.grey[900],
                                borderBottom: '1px solid',
                            }}
                            ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                            freeSolo={false}
                            disabled={disabled}
                            autoComplete={true}
                            onChange={onChange}
                            renderInput={(params) => {
                                params.InputProps = {...params.InputProps,
                                    style: { fontSize: '13px' },
                                };
                                params.InputLabelProps = {...params.InputLabelProps,
                                    style: {fontSize: '13px', color: theme.palette.grey.main },
                                };
                                return (
                                    <TextField {...params} variant={'standard'} label={label} sx={{p: 0}} />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        } else {
            return  (
                <Grid item display='flex'  justifyContent={'center'} p={0.5} my={1}>
                    <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={id}>
                            { label }
                        </InputLabel>
                        <Input
                            id={id}
                            inputProps={{style: { fontSize: '13px', textTransform: (label === 'VIN') ? 'uppercase' : 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                            sx={{width: '100%', border: '0'}}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    useEffect(() => {
        if (!locationOptions.length || !colorOptions.length)  {
            gatherOptions().catch(console.error);
        }
    }, [newJob.state]);

    return (
        <Grid item xs={7} ml={15}> 
            <Paper sx={{m: 1}}>
                <Grid container item display='flex' direction={'column'} justifyContent={'space-between'}>
                    <Grid item display='flex' justifyContent={'end'} alignItems={'center'} mt={2}>
                        <Grid item xs={6} display={'flex'} container direction={'column'}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 20}} alignSelf={'center'} >
                                New Job Request
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant={'text'}>
                            </Button>
                        </Grid>
                    </Grid>
                    <Card sx={{my: 1, mx: 10, py: 1, px: 2}}>
                        {/* LOCATION */}
                        { formInput(0,
                            'select-location',
                            (event, newValue) => { newJob.DISPATCH({type: 'SET_LOCATION', payload: newValue}); },
                            'Location',
                            newJob.state.location,
                            locationOptions,
                            (option) => option.nickname,
                            false)
                        }
                        {/* VIN */}
                        { formInput(1,
                            'select-vin',
                            (event) => { newJob.DISPATCH({type: 'SET_VIN', payload: event.target.value}); },
                            'VIN',
                            newJob.state.vin,
                            (option) => option,
                            false)
                        }
                        {/* COLOR */}
                        { formInput(0,
                            'select-color',
                            (event, newValue) => { newJob.DISPATCH({type: 'SET_COLOR', payload: newValue}); },
                            'Color',
                            newJob.state.color,
                            colorOptions,
                            (option) => option,
                            false)
                        }
                        {/* ODOMETER */}
                        { formInput(1,
                            'select-odo',
                            (event) => { newJob.DISPATCH({type: 'SET_ODO', payload: event.target.value}); },
                            'Odometer',
                            newJob.state.odometer)
                        }
                        {/* STOCK/RO */}
                        { formInput(1,
                            'select-sro',
                            (event) => { newJob.DISPATCH({type: 'SET_STOCK', payload: event.target.value}); },
                            'Stock/RO',
                            newJob.state.stockRO)
                        }
                        {/* PO NUMBER */}
                        { formInput(1,
                            'select-po',
                            (event) => { newJob.DISPATCH({type: 'SET_PO', payload: event.target.value}); },
                            'PO Number',
                            newJob.state.poNumber)
                        }
                    </Card>
                    <Grid item display='flex' justifyContent={'center'} mb={1}>
                        <Button
                            variant='outlined'
                            disabled={open}
                            onClick={() => {setOpen(true);}}
                            sx={{color: theme.palette.blue[700], borderColor: theme.palette.blue[700], m: 1, mt: 3, width: '60%', textTransform: 'none'}}>
                            Add Service
                        </Button>
                        <NewServiceModal open={open} setOpen={setOpen} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

NewJobForm.propTypes = {};

export default NewJobForm;
