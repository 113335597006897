import React, { useEffect, useState, useReducer } from 'react';
import {
    NavLink,
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/AuthContext';
import LoginScreen from 'screens/LoginScreen';
import Header from 'components/Header';
import JobsScreen from 'screens/JobsScreen';
import NewJobScreen from 'screens/NewJobScreen';
import NotFoundScreen from 'screens/NotFoundScreen';
import DashboardScreen from 'screens/DashboardScreen';
import AdminScreen from 'screens/AdminScreen';
import InvoicesScreen from 'screens/InvoicesScreen';
import { Alert, Button, Grid, Snackbar, styled } from '@mui/material';
import MiniDrawer from 'components/Drawer';
import { useLoading } from 'contexts/LoadingContext';
import Loading from 'components/Loading';
import { apiRodx } from '../config';
import { useError } from 'contexts/ErrorContext';
import { NewJobProvider } from 'services/reducers/newJob';
import { LocationProvider } from 'services/reducers/location';
import LocationsScreen from 'screens/LocationsScreen';

const AuthenticatedRoute = ({ user }) => {
    if (user === undefined) return setTimeout(() => <Loading loading={true} />, 250);
    // apiRodx.defaults.headers.common.Authorization = 'Bearer 1824346de77f72ab9ded64321f6736735252cc6b5bd3dacdbd823c16f333fc2a';
    
    return user
        ? <Outlet />
        : <Navigate to='/login' />;
};

AuthenticatedRoute.propTypes = {
    user: PropTypes.object,
};
  
const UnauthenticatedRoute = ({ user }) => {
    if (user === undefined) return setTimeout(() => <Loading loading={true} />, 250);
    
    return user
        ? <Navigate to='/admin' />
        : <Outlet />;
};

UnauthenticatedRoute.propTypes = {
    user: PropTypes.object,
};

const Router = () => {
    const {user} = useAuth();

    return (
        <Routes>
            <Route element={<UnauthenticatedRoute user={user} />}>
                <Route path='/' element={<LoginScreen />} />
                <Route path='/login' element={<LoginScreen />} />
            </Route>
            <Route element={<AuthenticatedRoute user={user} />}> 
                {/* all containers render inside of <Layout /> */}
                <Route element={<Layout />}>
                    <Route path='new_job' element={ <NewJobProvider> <NewJobScreen/> </NewJobProvider> } />
                    <Route path='jobs' element={<JobsScreen />} />
                    <Route path='dashboard' element={<DashboardScreen />} />
                    <Route path='invoices' element={<InvoicesScreen />} />
                    <Route path='admin' element={<AdminScreen/>} />
                    <Route path='locations' element={ <LocationProvider> <LocationsScreen/> </LocationProvider>} />
                </Route>
            </Route>
            <Route path='*' element={<NotFoundScreen />} />
        </Routes>
    );
};

const MainContainer = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(7), // Add margin to push content below AppBar
}));

const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const err = useError();
    const path = location.pathname;
    const [showDrawer, setSD] = useState(true);

    const resetError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // @ts-ignore
        err.DISPATCH({ type: 'RESET_ERROR' });
    };

    useEffect(() => {
        if ( path === '/new_job' ) setSD(false); // Offset from Drawer on certain screens
        else setSD(true);
    }, [path, err]);

    return (
        <>
            <Header />
            <MainContainer container justifyContent={'space-evenly'}>
                { err.state.showSnack && (
                    <Snackbar
                        open={err.state.showSnack}
                        autoHideDuration={6000}
                        onClose={resetError}
                    >
                        <Alert onClose={resetError} severity={err.state.severity} sx={{ width: '100%' }}>
                            {err.state.alert}
                        </Alert>
                    </Snackbar>
                )}
                { showDrawer && ( <MiniDrawer /> ) }
                { !showDrawer && ( 
                    <Grid item xs={2} sm={2} md={2} lg={2} />
                )}
                <Grid item xs={12} sm={9} md={10} lg={10}>
                    {/* https://reactrouter.com/en/main/components/outlet */}
                    {/* the other routes render inside of <Outlet /> */}
                    <Outlet />
                </Grid>
            </MainContainer>
        </>
    );
};

export default Router;