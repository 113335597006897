import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersPopper } from '@mui/x-date-pickers/internals/components/PickersPopper';
import { Box, Button, ClickAwayListener, Paper, Popper, TextField, Typography, styled, useTheme } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

export default function DatePicker({ dispatch, state }) {
    const theme = useTheme();
    const [startDate, setSDate] = React.useState(state.startDate);
    const [endDate, setEDate] = React.useState(state.startDate);
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        setStartDate(currentDate.valueOf());
        setEndDate(currentDate.valueOf());
    }, []);

    function setStartDate(date) {
        setSDate(date);
        dispatch({ type: 'set_start_date', payload: date });
    }

    function setEndDate(date) {
        setEDate(date);
        dispatch({ type: 'set_end_date', payload: date });
    }

    function formatDate(date) {
        const currentDate = new Date();

        date = new Date(date);

        // Check if it's today
        if (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            return 'Today';
        }

        // Check if it's the current year
        if (date.getFullYear() === currentDate.getFullYear()) {
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        } else {
            return date.toLocaleDateString('en-US');
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // React.useEffect(() => { console.log('startDate: ', startDate); }, [startDate]);

    function Day(props) {
        // if (props.selected) console.log(props);
        let isSelected = false;
        let isBetween = false;
        let isBeginning = false;
        let isEnd = false;

        if (props['data-timestamp'] === startDate) isSelected = true;
        if (props['data-timestamp'] === endDate) isSelected = true;
        if (startDate && endDate && (props['data-timestamp'] >= startDate && props['data-timestamp'] <= endDate)) {
            isBetween = true;
        }
        if (props['data-timestamp'] === startDate && endDate) isBeginning = true;
        if (props['data-timestamp'] === endDate && startDate) isEnd = true;

        if ([startDate, endDate].every((date) => date === props['data-timestamp'])) {
            isBeginning = false;
            isEnd = false;
            isBetween = false;
            isSelected = true;
        }

        return (
            <PickersDay
                onClick={(e) => {
                    if (props['data-timestamp'] === endDate) setStartDate(props['data-timestamp']);
                    if (props['data-timestamp'] === startDate) setEndDate(props['data-timestamp']);
                }}
                className={isBeginning ? 'begin' : isEnd ? 'end' : isSelected ? 'selected' : isBetween ? 'between' : ''}
                {...props}
            />
        );
    }

    Day.propTypes = {
        selected: PropTypes.any,
        'data-timestamp': PropTypes.any,
    };

    const open = Boolean(anchorEl);
    const id = open ? 'dateRange-label' : undefined;

    return (
        <React.Fragment>
            <Box sx={{
                border: '1px solid #CAD2D9',
                borderRadius: '8px',
                padding: '8px',
                paddingRight: '2px',
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '32px',
                fontSize: '12px',
            }}>
                <Button disableRipple aria-describedby={id} onClick={handleClick} sx={{ padding: 0, display: 'flex', '& *': { flexShrink: 0 }, flexShrink: 0 }}>
                    <DateRangeIcon sx={{ marginRight: '8px', color: theme.palette.blue.main, opacity: 1 }} />
                    <Typography variant={'subtitle2'} sx={{ textTransform: 'none', fontSize: '12px', color: theme.palette.grey[900] }}>{startDate === endDate ? formatDate(startDate) : `${formatDate(startDate)} - ${formatDate(endDate)}`}</Typography>
                    <ArrowDropDownIcon sx={{ color: 'black', marginLeft: '8px' }} />
                </Button>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <MuiDatePicker
                                disableFuture
                                onChange={(date) => {
                                    let timestamp = new Date(date.$d).valueOf();
                                    if (!startDate || timestamp < startDate) return setStartDate(timestamp);
                                    else if (timestamp === endDate) return setStartDate(timestamp);
                                    else return setEndDate(timestamp);
                                }}
                                open={open}
                                closeOnSelect={false}
                                inputRef={anchorEl}
                                slotProps={{
                                    popper: { id, anchorEl, placement: 'bottom-end' },
                                }}
                                slots={{
                                    textField: () => <></>,
                                    day: Day,
                                }}
                            />
                        </div>
                    </ClickAwayListener >
                </Popper >
            </LocalizationProvider >
        </React.Fragment>
    );
}

DatePicker.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
};
