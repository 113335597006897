// React
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as RepairersWIP } from '../../../assets/images/placeholders/RepairersWIP.svg';
import { useAuth } from 'contexts/AuthContext';
// Styles
import { Box, styled, Paper, Grid, Typography, useTheme, Button } from '@mui/material';

const Repairers = () => {
    const theme = useTheme();

    useEffect(() => {
    }, []);

    return (
        <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
            <RepairersWIP width={'100%'} height={'100%'}/>
        </Grid>
    );
};

export default Repairers;