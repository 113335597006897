import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';

export const getAllLocations = async function(params) {
    let config = params;
    const result = await apiRodx['get']('locations');
    return result;
};

export const getLocationById = async function(id) {
    let config = {};
    const result = await apiRodx['get'](`${'location'}/${id}`); 
    return result;
};

export const createLocation = async function(params) {
    let config = params;
    const result = await apiRodx['post']('location', params, config.data); 
    return result;
};

export const updateLocation = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`location/${config.locationID}`, config.data); 
    return result;
};

export const getLocations = async function(params) { //Temp
    const result = {data: { status: 200, payload: [
        { 'id': '6e8be023-32d3-11ee-baab-063d2160054b', 'nickname': '5 Springdale St',
            'formatted_address': '5 Springdale St Suite 1, St John\'s, NL A1A 1A1, Canada',
            'street_number': '5',
            'street_name': 'Stringdale St',
            'subpremise': 'Suite 1',
            'city': 'St John\'s',
            'state': 'Newfoundland and Labrador',
            'country': 'Canada',
            'zip': 'A1A 1A1'},
        { 'id': '4a2616cd-3b78-11ee-8429-029dd47fadcb', 'nickname': '6 Duckworth St',
            'formatted_address': '6 Duckworth St, St John\'s, NL A1A 1A1, Canada',
            'street_number': '6',
            'street_name': 'Duckworth St',
            'subpremise': '',
            'city': 'St John\'s',
            'state': 'Newfoundland and Labrador',
            'country': 'Canada',
            'zip': 'A1A 1A1'},
        { 'id': '76d41f3c-3b78-11ee-8429-029dd47fadcb', 'nickname': '11 Gower St',
            'formatted_address': '11 Gower St Apt 2209, St John\'s, NL A1A 1A1, Canada',
            'street_number': '11',
            'street_name': 'Gower St',
            'subpremise': 'Apt 2209',
            'city': 'St John\'s',
            'state': 'Newfoundland and Labrador',
            'country': 'Canada',
            'zip': 'A1A 1A1'},
        { 'id': '77e05315-3b78-11ee-8429-029dd47fadcb', 'nickname': '31 Weymouth St',
            'formatted_address': '31 Weymouth St, St John\'s, NL A1A 1A1, Canada',
            'street_number': '31',
            'street_name': 'Weymouth St',
            'subpremise': '',
            'city': 'St John\'s',
            'state': 'Newfoundland and Labrador',
            'country': 'Canada',
            'zip': 'A1A 1A1'},
        { 'id': 'b331e1c3-3b86-11ee-8429-029dd47fadcb', 'nickname': '74 Gower St',
            'formatted_address': '74 Gower St Suite 1, St John\'s, NL A1A 1A1, Canada',
            'street_number': '74',
            'street_name': 'Gower St',
            'subpremise': '',
            'city': 'St John\'s',
            'state': 'Newfoundland and Labrador',
            'country': 'Canada',
            'zip': 'A1A 1A1'},
    ]}};
    return result;
};
