// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { useAuth } from 'contexts/AuthContext';
import CompanyInfo from './content/CompanyInfo';
import Repairers from './content/Repairers';
import Users from './content/Users';
import Settings from './content/Settings';
import Developers from './content/Developers';
// Styles
import { Box, styled, Grid, Typography, useTheme, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Mail } from '@mui/icons-material';
import './styles.css';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const NewButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    borderRadius: '8px',
    background: theme.palette.blue.main,
    color: 'white',
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
}));

const AdminScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const pills = ['Company Info', 'Repairers', 'Users', 'Settings', 'Developers'];
    const [currentTab, setCurrentTab] = useState('Developers');

    const handlePillClick = function (pill) {
        setCurrentTab(pill);
    };

    useEffect(() => {
    }, [currentTab]);

    const pillBar = function () {
        return pills.map((pill, i) => {
            return (<Grid item key={i}> 
                <Button
                    onClick={() => handlePillClick(pill)}
                    sx={{ p: '3px', m: '4px', fontSize: '16px', textTransform: 'none',
                        color: (pill === currentTab) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(pill === currentTab) ? 'active': ''}
                > { pill } </Button>
            </Grid>);
        });
    };

    const newButton = function() {
        switch (currentTab) {
            case 'Repairers':
                return  (
                    <NewButton
                        variant='contained'
                        // onClick={() => navigate('/new_repairer')}
                        startIcon={<Mail/>}
                    >
                        Invite Repairer
                    </NewButton>
                );
            case 'Users':
                return  (
                    <NewButton
                        variant='contained'
                        // onClick={() => navigate('/new_user')}
                        startIcon={<Mail/>}
                    >
                          Invite User
                    </NewButton>
                );
            default:
                return;
        }
    };

    const showContent = function () {
        switch (currentTab) {
            case 'Company Info':
                return (<CompanyInfo/>);
            case 'Repairers': 
                return (<Repairers/>);
            case 'Users': 
                return (<Users/>);
            case 'Settings': 
                return (<Settings/>);
            case 'Developers': 
                return (<Developers/>);
            default:
                return (<CompanyInfo/>);
        }
    };

    return (
        <Grid container display={'flex'} direction={'column'}>
            <Typography variant={'h1'} m={1} p={1}> Administration </Typography>
            <Grid item display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} > { pillBar() } </Box>
                { newButton() }
            </Grid>
            <Grid item container display={'flex'} my={4}>
                <ContentContainer>
                    { showContent() }
                </ContentContainer>
            </Grid>
        </Grid >
    );
};

export default AdminScreen;