/* eslint-disable camelcase */
// React
import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
// Local
import JobCard from './JobCard';
import { getJobById } from 'services/actions/job';
import { useError } from 'contexts/ErrorContext';
import { STATUS_BAR_CLASS, STATUS_BAR_DISPLAY } from 'services/utils/enums';
// Styles
import { Paper, useTheme } from '@mui/material';

const JobList = ({dispatch, state}) => {
    const err = useError();
    const theme = useTheme();

    useEffect(() => {
        if (!state.activeJob) {
            if (state.job && state.job.id) {
                setActiveCard(state.job.id);
            } else setActiveCard(state.jobs[0].id);
        }

    }, [state.job, state.activeJob]);

    const formatJob = function (job) {
        // let { created_at, repairer_company, price, location, id, vehicle } = job;
        const getTimestamp = (createdAt) => {
            const hoursSince = Math.abs(new Date() - new Date(createdAt)) / 3600000;
            if (hoursSince <= .05) {
                return 'New';
            }
            if (hoursSince <= 1) {
                return `${Math.floor(hoursSince*60)}mins ago`;
            }
            if (hoursSince > 1 && hoursSince <= 24) {
                return `${Math.floor(hoursSince)}hrs ago`;
            }
            if (hoursSince > 24 && hoursSince <= (24*30)) {
                return `${Math.floor(hoursSince/24)}days ago`;
            }
            if (hoursSince > (24*30) && hoursSince <= (24*365)) {
                return `${Math.floor(hoursSince/24/30)}months ago`;
            }
            return `${Math.floor(hoursSince/24/30/365)}years ago`;
        };

        const getJobStatus = function (services) {
            // @ts-ignore
            const s = [...new Set(services.map(el => STATUS_BAR_DISPLAY[el.service_status]))].map((el) => {
                return {
                    status: el,
                    statusStyle: STATUS_BAR_CLASS[el],
                };
            });
            return s;
        };
        if (job) {
            return {
                timestamp: getTimestamp(job.created_at),
                jobStatuses: getJobStatus(job.services),
                // @ts-ignore
                color: job.vehicle.color || '',
                location: job.location.nickname,
                repairerList: [...new Set(job.services.map(el => el.repair_company.name))].join(', '),
                serviceList: [...new Set(job.services.map(el => el.service_details.service_type))].join(', '),
                numServices: job.services.length, // number of services on the job
                total: job.services.reduce((sum, part) => sum += part.price, 0), // dollar amount of combined jobs (total repair order) with cents
                currency: (job.currency) ? job.currency.abbreviation : 'USD', // ISO currency code
                stockRO: job.vehicle.stock_number || '',
                vin: job.vehicle.vin || '',
                year: job.vehicle.year || '',
                make: job.vehicle.make || '',
                model: job.vehicle.model || '',
                id: job.id,
            };
        }
    };
    
    const setActiveCard = async function (id) {
        dispatch({type: 'set_active_card', payload: id});
        try {
            const data = await getJobById(id);
            if (data && data.data.status === 200 && data.data.payload) {
                dispatch({type: 'get_job', payload: data.data.payload});
                // @ts-ignore
                err.DISPATCH({type: 'NO_ERROR', payload: {alert: 'Found that Job!!', status: data.data.status}});
            } 
        } catch (error) {
            // @ts-ignore
            err.DISPATCH({type: 'API_ERROR', payload: error});
        }
    };

    const showJobCards = () => {
        return state.jobs.map((job, i) => {
            return (    
                <JobCard data={ formatJob(job) } key={i} job={job} state={state} setActiveCard={setActiveCard}/>
            );
        });
    };

    return (
        <Paper sx={{backgroundColor: theme.palette.background.paper, pb: 4}}>
            { showJobCards() }
        </Paper>
        
    );
};

JobList.propTypes = {
    dispatch: PropTypes.func.isRequired, 
    state: PropTypes.object.isRequired, 
};

export default JobList;