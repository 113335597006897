// React
import React, { useEffect, useState } from 'react';
// Local
import { useLocations } from 'services/reducers/location';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// Styles
import { Autocomplete, Box, Button, Collapse, Divider, FormControl, Grid, Input, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, Paper, Switch, TextField, Typography, alpha, styled, useTheme } from '@mui/material';
import { StarBorder } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const LocationDetails = () => {
    // app context
    const theme = useTheme();
    const LOCATION = useLocations();
    const inputRef = document.getElementById('select-add1');
    const [view, setView] = useState({service: true, repairer: false});

    const PrimarySwitch = styled(Switch)(({ theme }) => ({
        padding: 6,
        '& .MuiSwitch-track': {
            borderRadius: 50,
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 20,
            height: 20,
            margin: 0,
        },
        '& .MuiSwitch-switchBase': {
            color: theme.palette.white,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: theme.palette.red.main,
            opacity: 1,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: theme.palette.white,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.blue.main,
            opacity: 1,
        },
    }));
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    
    const formInput = function(formType, id, onChange, label, stateVar, options, getOptionLabel, disabled) {
        if (formType === 0) {
            return (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <Autocomplete
                            id={id}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            sx={{
                                pl: 1,
                                borderRadius: 0, 
                                border: 0,
                                borderBottomColor: theme.palette.grey[900],
                                borderBottom: '1px solid',
                            }}
                            ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                            freeSolo={false}
                            disabled={disabled}
                            autoComplete={true}
                            onChange={onChange}
                            renderInput={(params) => {
                                params.InputProps = {...params.InputProps,
                                    style: { fontSize: '13px' },
                                };
                                params.InputLabelProps = {...params.InputLabelProps,
                                    style: {fontSize: '13px', color: theme.palette.grey.main },
                                };
                                return (
                                    <TextField {...params} variant={'standard'} label={label} sx={{p: 0}} />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        } else {
            return  (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={id}>
                            { label }
                        </InputLabel>
                        <Input
                            id={id}
                            inputProps={{style: { fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                            sx={{width: '100%', border: '0'}}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const onChangeAddress = (e) => {
        setHasDataChanged(false);
        console.log('value: ', e.target.value);
        setHasDataChanged(true);
    };
    const [hasDataChanged, setHasDataChanged] = useState(false);
    useEffect(() => {
        if (!hasDataChanged) return;
        // eslint-disable-next-line no-undef
        const autoComplete = new google.maps.places.Autocomplete(inputRef, {
            // componentRestrictions: { country: ['us', 'ca'] },
            fields: ['address_components', 'geometry'],
            types: ['address'],
        });
        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace();
            console.log(place);
        });
    }, [inputRef, hasDataChanged]);

    const [open, setOpen] = useState(true);

    return (
        <Paper elevation={0} sx={{pb: 4, width: '100%'}}>
            <Grid container justifyContent={'center'} display={'flex'}>  
                <Grid item sx={{ height: '50px', width: '100%', px: 2}} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                    <Typography sx={{ fontSize: 14, fontWeight: 500}} color={theme.palette.grey[900]}> { 'Details' } </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography sx={{mx: 1}}> { (checked) ? 'Active' : 'Inactive'} </Typography>
                        <PrimarySwitch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>                        
                </Grid>
                <Divider sx={{width: '100%', mb: 1}}/>
                <Grid container>
                    <Grid xs={2}></Grid>
                    <Grid xs={8}>
                        <Typography textAlign={'center'} my={2.5} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 400}} > { LOCATION.state.location.nickname || 'Location Nickname' } </Typography>
                        <Paper variant={'outlined'} elevation={0} sx={{width: '100%', backgroundColor: theme.palette.white, mb: 4}}>
                            <Grid container flexDirection={'column'} display={'flex'} sx={{ width: '100%', px: 2}} justifyContent={'space-between'} my={1} pb={2}>
                                <List>
                                    <ListItemButton onClick={() => setOpen(!open)}>
                                        <Typography textAlign={'center'} sx={{ width: '100%', fontSize: 16, fontWeight: 500}} color={theme.palette.grey[700]} my={1}> Details </Typography>
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open} timeout='auto' unmountOnExit>
                                        <List component='div' disablePadding>
                                            {/* LOCATION NICKNAME */}
                                            { formInput(1,
                                                'select-nickname',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_NICKNAME', payload: event.target.value}); },
                                                'Location Name',
                                                LOCATION.state.nickname)
                                            }
                                            {/* ADDRESS 1 */}
                                            <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                                                <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (LOCATION.state.add1) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                                                    <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={'select-add1'}>
                                                        { 'Address 1' }
                                                    </InputLabel>
                                                    <Input
                                                        id={'select-add1'}
                                                        inputProps={{style: { fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                                                        sx={{ width: '100%', border: '0' }}
                                                        onChange={onChangeAddress}
                                                        // onChange={(event) => { LOCATION.DISPATCH({type: 'SET_ADDRESS1', payload: event.target.value}); }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {/* ADDRESS 2 */}
                                            { formInput(1,
                                                'select-add2',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_ADDRESS2', payload: event.target.value}); },
                                                'Address 2',
                                                LOCATION.state.add2)
                                            }
                                            <Typography textAlign={'center'} mt={1} sx={{fontWeight: 300, color: theme.palette.grey[500]}}> Location Contact Information </Typography>
                                            {/* FULL NAME */}
                                            { formInput(1,
                                                'select-fullname',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_FNAME', payload: event.target.value}); },
                                                'Contact Name',
                                                LOCATION.state.fName)
                                            }
                                            {/* LAST NAME */}
                                            {/* { formInput(1,
                                                'select-lname',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_LNAME', payload: event.target.value}); },
                                                'Last Name',
                                                LOCATION.state.lName)
                                            } */}
                                            {/* EMAIL */}
                                            { formInput(1,
                                                'select-email',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_EMAIL', payload: event.target.value}); },
                                                'Email',
                                                LOCATION.state.email)
                                            }
                                            {/* PHONE */}
                                            { formInput(1,
                                                'select-phone',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_PHONE', payload: event.target.value}); },
                                                'Phone',
                                                LOCATION.state.phone)
                                            }
                                            {/* BILLING */}
                                            { formInput(0,
                                                'select-billing',
                                                (event, newValue) => { LOCATION.DISPATCH({type: 'SET_BILLING', payload: newValue}); },
                                                'Payment Options',
                                                LOCATION.state.billing,
                                                ['Credit Card 1', 'Bank #12345', 'Credit Card 4'], // billingOptions
                                                (option) => option,
                                                false)
                                            }
                                            {/* NOTES */}
                                            { formInput(1,
                                                'select-notes',
                                                (event) => { LOCATION.DISPATCH({type: 'SET_NOTES', payload: event.target.value}); },
                                                'Notes',
                                                LOCATION.state.notes)
                                            }
                                            <Grid item display='flex' justifyContent={'start'} ml={7}><Typography sx={{fontSize: 10, fontWeight: 300, color: theme.palette.grey[300]}}> Notes are displayed to Repairers. </Typography></Grid>
                                        </List>
                                    </Collapse>
                                </List>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid xs={1}>
                        { LOCATION.state.editing && ( <Button sx={{
                            textTransform: 'none',
                            borderRadius: 2,
                            background: theme.palette.blue.main,
                            color: theme.palette.white, m: 2, alignSelf:'start'}} variant={'contained'}> Save </Button>
                        )}
                    </Grid>
                    <Grid xs={1}></Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default LocationDetails;