import React, { useEffect, useState } from 'react';
import { Box, styled, IconButton, ClickAwayListener, Popper, Paper, Typography, Button } from '@mui/material';
import { NotificationsNone as NotificationsIcon, ChatBubbleOutline as ChatIcon, PersonOutline as PersonIcon } from '@mui/icons-material';
import { useAuth } from 'contexts/AuthContext';
import { ReactComponent as NotificationsWIP } from '../../assets/images/placeholders/NotificationsWIP.svg';
import { ReactComponent as ChatWIP } from '../../assets/images/placeholders/ChatWIP.svg';

const IconsContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
    gap: '16px',
    width: '182px',
    height: '56px',
    flex: 'none',
    flexGrow: 0,
}));

const HeaderButton = styled(IconButton)(({theme}) => ({
    backgroundColor: theme.palette.grey[100],
    '& .MuiSvgIcon-root': {
        color: theme.palette.grey[800],
    },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    boxShadow: theme.shadows[3],
    borderRadius: '8px',
    padding: '8px',
    zIndex: theme.zIndex.modal,
    fontSize: '12px',
    backgroundColor: 'white',
}));

const HeaderIcons = () => {
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [notifyAnchorEl, setNotifyAnchorEl] = useState(null);
    const [chatAnchorEl, setChatAnchorEl] = useState(null);
    const auth = useAuth();

    const showNotifyPopper = Boolean(notifyAnchorEl);
    const showChatPopper = Boolean(chatAnchorEl);
    const showUserPopper = Boolean(userAnchorEl);
    
    return (
        <React.Fragment>
            <IconsContainer>
                {/* NOTIFICATIONS */}
                <HeaderButton onClick={(e) => {setNotifyAnchorEl(e.currentTarget);}}>
                    <NotificationsIcon />
                </HeaderButton>
                <StyledPopper open={showNotifyPopper} anchorEl={notifyAnchorEl} placement='bottom-start' nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                    <ClickAwayListener
                        onClickAway={() => {setNotifyAnchorEl(null);}}
                    >
                        <NotificationsWIP width={'100%'} height={'100%'} />
                    </ClickAwayListener>
                </StyledPopper>
                {/* CHAT */}
                <HeaderButton onClick={(e) => {setChatAnchorEl(e.currentTarget);}}>
                    <ChatIcon />
                </HeaderButton>
                <StyledPopper open={showChatPopper} anchorEl={chatAnchorEl} placement='bottom-start' nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                    <ClickAwayListener
                        onClickAway={() => {setChatAnchorEl(null);}}
                    >
                        <ChatWIP width={'100%'} height={'100%'} style={{padding: 0, margin: 0}}/>
                    </ClickAwayListener>
                </StyledPopper>
                {/* USER/SESSION CONTROLS */}
                <HeaderButton onClick={(e) => {setUserAnchorEl(e.currentTarget);}}>
                    <PersonIcon />
                </HeaderButton>
                <StyledPopper open={showUserPopper} anchorEl={userAnchorEl} placement='bottom-start' nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                    <ClickAwayListener
                        onClickAway={() => {setUserAnchorEl(null);}}
                    >
                        <Button onClick={((e) => {
                            console.log(auth);
                            // @ts-ignore
                            auth.logout();
                        })}>
                        Logout
                        </Button>
                    </ClickAwayListener>
                </StyledPopper>
            </IconsContainer>
        </React.Fragment>
    );
};

export default HeaderIcons;
