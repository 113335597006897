import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const INIT = function () {
    return INITIAL_STATE;
};

export const INITIAL_STATE = {
    // ALL SNACKS
    showSnack: false,
    hasError: false,
    severity: '', // error, warning, info, success
    status: '',
    alert: '',
    // ERROR MESSAGE ONLY
    error: '',
    info: '',
    stack: '',
    type: '',
    message: '',
};

export const ErrorReducer = function (state, action) {
    console.log(JSON.stringify(action, null, 2));
    switch (action.type) {
        case 'NO_ERROR':
            return {...state,
                hasError: false,
                alert: action.payload.alert,
                severity: 'success',
                status: action.payload.status || 500,
                showSnack: true,
            };
        case 'API_ERROR':
            return {...state,
                message:  action.payload.message,
                alert: 'An error has occurred with the server.',
                stack: action.payload.stack,
                name: action.payload.name,
                cause: action.payload.cause,
                hasError: true,
                type: 'api_error',
                severity: 'error',
                status: action.payload.status || 500,
                showSnack: true,
            };
        case 'INVALID_INPUT':
            return {...state,
                hasError: true,
                alert: action.payload.alert,
                severity: 'warning',
                showSnack: true,
            };
        case 'RESET_ERROR':
            return INIT();
        default:
            return {...state, ...action.payload};
    }
};

export const ErrorContext = createContext({});

export const ErrorProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ErrorReducer, INITIAL_STATE, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <ErrorContext.Provider value={value}> { children } </ErrorContext.Provider>
    );
};

ErrorProvider.propTypes = {
    children: PropTypes.node,
};

export const useError = () => useContext(ErrorContext);
