import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { AddLocationAlt } from '@mui/icons-material';
import WorkIcon from '@mui/icons-material/Work';
import PropTypes from 'prop-types';

export default function NavMenu({ route = '' }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(location.pathname);

    function handleClick(path) {
        setOpen(path);
        navigate(path);
    }

    return (
        <MenuList sx={{}}>
            {/* TODO: Menu Collapse Button */}
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick()}>
                <ListItemIcon>
                    <div style={{height: '20px', display: 'inline-block'}}></div>
                    {/* <MenuOpenIcon sx={{ color: 'grey.700' }} fontSize='small' /> */}
                </ListItemIcon>
            </MenuItem>
            <MenuItem sx={{ backgroundColor: 'white', p: 1, borderRadius: '8px', border: '1px solid #E2E8EE'}} onClick={() => handleClick('/new_job')} >
                <ListItemIcon>
                    <ControlPointIcon sx={{ color: 'blue.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText>New Job</ListItemText>
            </MenuItem>
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon sx={{ color: (open === '/dashboard') ? 'blue.700' : 'grey.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText sx={{ color: (open === '/dashboard') ? 'blue.700' : 'grey.700' }} >Dashboard</ListItemText>
            </MenuItem>
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick('/jobs')}>
                <ListItemIcon>
                    <WorkIcon sx={{ color: (open === '/jobs') ? 'blue.700' : 'grey.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText sx={{ color: (open === '/jobs') ? 'blue.700' : 'grey.700' }}>Jobs</ListItemText>
            </MenuItem>
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick('/invoices')}>
                <ListItemIcon>
                    <ListAltIcon sx={{ color: (open === '/invoices') ? 'blue.700' : 'grey.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText sx={{ color: (open === '/invoices') ? 'blue.700' : 'grey.700' }} >Invoices</ListItemText>
            </MenuItem>
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick('/locations')}>
                <ListItemIcon>
                    <AddLocationAlt sx={{ color: (open === '/locations') ? 'blue.700' : 'grey.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText sx={{ color: (open === '/locations') ? 'blue.700' : 'grey.700' }}>Locations</ListItemText>
            </MenuItem>
            <MenuItem sx={{ p: 1 }} onClick={() => handleClick('/admin')}>
                <ListItemIcon>
                    <SettingsIcon sx={{ color: (open === '/admin') ? 'blue.700' : 'grey.700' }} fontSize='small' />
                </ListItemIcon>
                <ListItemText sx={{ color: (open === '/admin') ? 'blue.700' : 'grey.700' }}>Admin</ListItemText>
            </MenuItem>
        </MenuList>
    );
}

NavMenu.propTypes = {
    route: PropTypes.string,
};