// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as LocationSymbol } from '../../../assets/images/location_symbol.svg';
// Styles
import { Box, Card, Grid, Paper, Typography, styled, useTheme } from '@mui/material';
import { useLocations } from 'services/reducers/location';
import { useError } from 'contexts/ErrorContext';
import { getLocationById } from 'services/actions/location';

const LocationCard = ({ location, setActiveCard}) => {
    const theme = useTheme();
    const err = useError();
    const LOCATION = useLocations();

    const styles = {
        activeCard: {
            backgroundColor: theme.palette.blue[30],
            border: '1px solid',
            borderColor: theme.palette.blue[200],
            mx: .5,
            my: 1,
            p: 1,
            borderRadius: 1,
        },
        inactiveCard: {
            backgroundColor: theme.palette.white,
            border: '1px solid',
            borderColor: theme.palette.grey[200],
            m: .5,
            p: 1,
            borderRadius: 1,
        },
    };

    useEffect(() => {
    }, []);

    return (
        <Card
            raised={true}
            onClick={() => { setActiveCard(location.id); }}
            sx={ (LOCATION.state.activeLocation === location.id) ? styles.activeCard : styles.inactiveCard }>
            <Grid display={'flex'} alignItems={'center'}>
                <LocationSymbol width={66} height={66}/>
                <Grid item mx={1}>
                    <Typography variant={'h5'} my={.5}> {location.nickname || 'Location Nickname' } </Typography> 
                    <Typography my={.5} sx={{fontSize: 12, color: theme.palette.grey[500]}}> {location.address } </Typography> 
                </Grid>
            </Grid>
        </Card>
    );
};

LocationCard.propTypes = {
    location: PropTypes.object.isRequired, 
    setActiveCard: PropTypes.func.isRequired,
};

const LocationList = () => {
    // app context
    const theme = useTheme();
    const err = useError();
    const LOCATION = useLocations();

    const formatLocation = function (location) {
        if (location) {
            return {
                nickName: location.nickname,
                address: location.formatted_address,
                id: location.id,
            };
        }
    };
    
    const setActiveCard = async function (id) {
        LOCATION.DISPATCH({type: 'SET_ACTIVE_LOCATION', payload: id});
        try {
            const data = await getLocationById(id);
            if (data && data.data.status === 200 && data.data.payload) {
                LOCATION.DISPATCH({type: 'GET_LOCATION', payload: data.data.payload});
                // @ts-ignore
                err.DISPATCH({type: 'NO_ERROR', payload: {alert: 'Found that Location!!', status: data.data.status}});
            } 
        } catch (error) {
            // @ts-ignore
            err.DISPATCH({type: 'API_ERROR', payload: error});
        }
    };

    const showLocationCards = () => {
        return LOCATION.state.locations.map((location, i) => {
            return (    
                <LocationCard location={ formatLocation(location) } key={i} setActiveCard={setActiveCard}/>
            );
        });
    };

    useEffect(() => {
        if (!LOCATION.state.activeLocation) {
            if (LOCATION.state.location && LOCATION.state.location.id) {
                setActiveCard(LOCATION.state.location.id);
            } else setActiveCard(LOCATION.state.locations[0].id);
        }

    }, [LOCATION.state.location, LOCATION.state.activeLocation]);

    return (
        <Paper sx={{backgroundColor: theme.palette.background.default, px: .5}} elevation={0}>
            { showLocationCards() }
        </Paper>
    );
};

export default LocationList;