import axios from 'axios';

export const configVars = {
    env: process.env.REACT_APP_env || 'local',
    host: process.env.REACT_APP_host || 'https://test-api.repairondemand.com',
    apiKey: process.env.REACT_APP_apiKey || 'AIzaSyBxk5DtlmmHihNIqudDqFC44mHOSkTKEH4',
    authDomain: process.env.REACT_APP_authDomain || 'repair-ondemand.firebaseapp.com',
    projectId: process.env.REACT_APP_projectId || 'repair-ondemand',
    storageBucket: process.env.REACT_APP_storageBucket || 'repair-ondemand.appspot.com',
    messagingSenderId: process.env.REACT_APP_messagingSenderId || '860517169068',
    appId: process.env.REACT_APP_appId || '1:860517169068:web:3180a6b4234f999ba9ac09',
    measurementId: process.env.REACT_APP_measurementId || 'G-D1FT041W4L',
    googleMapsKey: process.env.REACT_APP_googleMapsKey || '',
};

export const apiRodx = axios.create({
    baseURL: configVars.host,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});