import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Grid, Typography } from '@mui/material';

import loadingIMG from '../../assets/images/logo.svg';

const NotFoundScreen = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    <Card sx={{ mt: 10, p: 5 }}>
                        <Grid container direction='column' alignItems='center' justifyContent='center' >
                            <img
                                width='212'
                                style={{
                                    margin: 10,
                                }}
                                src={loadingIMG}
                                alt={''}
                            />
                            <Typography variant='h1' m={2} p={2}>
                There is nothing here...
                            </Typography>
                            <Typography variant='h3' m={2} p={2}>
                404. Page Not Found
                            </Typography>
                            <Button
                                type='button'
                                color='secondary'
                                variant='contained'
                                onClick={() => navigate('/login')}
                                sx={{
                                    m: 2,
                                    borderRadius: '100px',
                                    padding: '8px 40px',
                                    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                                    fontWeight: '600',
                                    fontSize: '14px',
                                }}
                            >
                Login Page </Button>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item>
                    <Typography variant='h5' m={2} p={2}>
                        <strong>Repair</strong>OnDemand
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotFoundScreen;