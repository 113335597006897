/* eslint-disable no-constant-condition */
// React
import React, { useState, useRef, useEffect } from 'react';
// Local
import { ReactComponent as MediaRemove } from '../../../assets/images/remove_circle.svg';
import { ReactComponent as MediaUploadPDF } from '../../../assets/images/media_pdf.svg';
import { ReactComponent as MediaUploadPic } from '../../../assets/images/media_pic.svg';
import { getRepairVendors } from '../../../services/actions/repairer';
import { gatherCatalog } from 'services/utils/enums';
import { useNewJob } from 'services/reducers/newJob';
// Styles
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Grid,
    Input,
    InputLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme,
    Checkbox,
    IconButton,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ModalForm = () => {
    const theme = useTheme();
    const newJob = useNewJob();
    const [view, setView] = useState({service: true, repairer: false});
    const currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).formatToParts(0).map(el => el.value)[0];

    const [vendorOptions, setVendorOptions] = useState([]);
    const [repairCatalog, setRepairCatalog] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [repairOptions, setRepairOptions] = useState([]);
    const [partsOptions, setPartsOptions] = useState([]);

    const gatherOptions = async function() {
        const vendors = await getRepairVendors({});
        const catalog = await gatherCatalog({});
        if (catalog && catalog.data.status === 200 && catalog.data.payload) {
            setVendorOptions(vendors);
            setRepairCatalog(catalog.data.payload);
            // @ts-ignore
            const services = [...new Set(catalog.data.payload.map((el) => el.service_detail.service_type))];
            setServiceOptions(services);
        }
    };

    const fileInput = useRef(null);
    const MediaInputButton = function() {
        return (
            <div>
                <Button
                    color='primary' 
                    onClick={()=>fileInput.current.click()}
                
                >
                    <MediaUploadPDF/>
                    <MediaUploadPic/>
                </Button>
  
                <input 
                    ref={fileInput} 
                    type='file' 
                    style={{ display: 'none' }}
                    multiple={true}
                    accept='application/pdf,video/*,image/*'
                    onChange={e => {
                        renderMedia(e.target.files);
                    }}
                />
            </div>
        );
    };

    const renderMedia = async function(files) {
        let data = [];
        // Build Promises of each File
        Array.from(files).forEach(async (element) => {
            let payload = new Promise((res, rej) => {
                const rendReader = new FileReader();
                rendReader.onload = () => {
                    const MEDIA_TYPES = {'image': 'Image', 'video': 'Video', 'application':'PDF'};
                    // Final data shape of Media object
                    res({
                        'caption': element.name || 'no caption',
                        'contentHeader': element.type,
                        'media_type': MEDIA_TYPES[element.type.split('/')[0]],
                        'localPath': rendReader.result,
                        'data': element,
                    });
                };
                rendReader.readAsDataURL(element);   
            });
            data.push(payload);
        });
        // Trigger Promises
        Promise.all(data).then((values) => {
            newJob.DISPATCH({type: 'SET_MEDIA', payload: [...newJob.state.service.media, ...values]});
        });
    };

    const showThumbnails = function() {
        return newJob.state.service.media.map((tn, i) => {
            // TODO check if iframe, video, or img tag is needed for display
            const formatThumbnail = function(med) {
                if (med.media_type === 'PDF') {
                    return <iframe width='70' height='70' src={med.localPath}></iframe>;
                } else if (med.media_type === 'Video') {
                    return <video width='70' height='70' controls>
                        <source src={med.localPath} type='video/mp4'/>
                    </video>;
                }
                return <img
                    src={med.localPath}
                    width='70' height='70'
                    style={{}}
                />;
            };
            
            return (
                <div key={i} style={{ position: 'relative' }}>
                    <Card variant='outlined' sx={{p: 0, m: .25}}>
                        { formatThumbnail(tn) }
                    </Card>
                    <IconButton
                        onClick={() => {
                            const values = [...newJob.state.service.media.filter(el => el.caption != tn.caption)];
                            newJob.DISPATCH({type: 'SET_MEDIA', payload: [...values]});
                        }}
                        sx={{ '&:hover': {
                            backgroundColor: theme.palette.blue.main,
                        }, p: 0, m: 0, border: 0, position: 'absolute', bottom: '-4px', right: '-4px', backgroundColor: theme.palette.white}}>
                        <MediaRemove />
                    </IconButton>
                </div>
            );},
        );
    };
    
    useEffect(() => {
        if (!serviceOptions.length || !repairCatalog.length)  {
            gatherOptions().catch(console.error);
        }
        if (newJob.state.service.type) {
            setRepairOptions([]);
            setPartsOptions([]);
            const repairs = [
                // @ts-ignore
                ...new Set(repairCatalog
                    .filter((el) => el.service_detail.service_type == newJob.state.service.type)
                    .map((el) => el.service_detail.repair_type)),
            ];
            setRepairOptions(repairs[0] == null ? [] : repairs);
          
        }
        if (newJob.state.service.type || newJob.state.service.repair) {
            setPartsOptions([]);
            const parts = [
                // @ts-ignore
                ...new Set(repairCatalog
                    .filter((el) => {
                        if (newJob.state.service.repair) {
                            return ( el.service_detail.service_type == newJob.state.service.type 
                                && el.service_detail.repair_type == newJob.state.service.repair
                            );
                        } else {
                            return ( el.service_detail.service_type == newJob.state.service.type );
                        }
                        
                    })
                    .map((el) => { return { part: el.service_detail.part, position: el.service_detail.position}; })),
            ];
            setPartsOptions(parts[0] == null ? [] : parts);
        }
    }, [view, newJob.state, newJob.state.service.media, repairCatalog]);

    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    return (
        <>
            <Grid item>
                { view.service && (
                    <Paper sx={{p:2, mx: 8, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)'}}>
                        <Grid item display={'flex'} justifyContent={'end'} alignItems={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16}}> Service </Typography>
                            <KeyboardArrowDownIcon sx={{ml: '45%', color: theme.palette.grey.main}}/>
                        </Grid>
                        {/* QUICK FIND */}
                        {/* <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Autocomplete
                                id='quick-find'
                                options={serviceOptions}
                                sx={{
                                    width: '88%',
                                    mt: 1,
                                    p: 4,
                                    borderRadius: 6, 
                                    border: 0,
                                    backgroundColor: theme.palette.grey[50],
                                }}
                                ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                freeSolo={false}
                                disabled={true}
                                renderInput={(params) => {
                                    params.InputProps = {...params.InputProps,
                                        style: { fontSize: '15px' },
                                    };
                                    params.InputLabelProps = {...params.InputLabelProps,
                                        style: {fontSize: '15px', color: theme.palette.grey[800] },
                                    };
                                    return (
                                        <TextField {...params} variant={'standard'} label='Quick Find (coming soon...)' />
                                    );
                                }}
                            />
                        </Grid> */}
                        {/* SERVICE TYPE | REPAIR TYPE */}
                        <Grid item display='flex' justifyContent={'space-between'} p={1} m={1}>
                            <Autocomplete
                                sx={{
                                    mt: 1,
                                    borderRadius: 0, 
                                    border: 0,
                                    borderBottomColor: theme.palette.grey[900],
                                    borderBottom: '1px solid',
                                    ml: 4, width: '42%',
                                }}
                                disablePortal
                                id='select-serviceType'
                                openOnFocus={false}
                                options={serviceOptions}
                                value={newJob.state.service.type}
                                // @ts-ignore
                                onInputChange={ (event, newValue) => { newJob.DISPATCH({type: 'SET_TYPE', payload: newValue}); } }
                                renderInput={(params) => {
                                    params.InputProps = {...params.InputProps,
                                        style: { fontSize: '14px' },
                                    };
                                    params.InputLabelProps = {...params.InputLabelProps,
                                        style: {fontSize: '14px', color: theme.palette.grey[800] },
                                    };
                                    return (<TextField {...params} sx={{p: 0}} variant={'standard'} label='Service Type' />);
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                id='select-repairType'
                                openOnFocus={true}
                                options={repairOptions}
                                value={newJob.state.service.repair}
                                // @ts-ignore
                                onInputChange={ (event, newValue) => { newJob.DISPATCH({type: 'SET_REPAIR', payload: newValue}); } }
                                sx={{
                                    mt: 1,
                                    borderRadius: 0, 
                                    border: 0,
                                    borderBottomColor: theme.palette.grey[900],
                                    borderBottom: '1px solid',
                                    mr: 4, width: '42%',
                                }}
                                renderInput={(params) => {
                                    params.InputProps = {...params.InputProps,
                                        style: { fontSize: '14px' },
                                    };
                                    params.InputLabelProps = {...params.InputLabelProps,
                                        style: {fontSize: '14px', color: theme.palette.grey[800] },
                                    };
                                    return (<TextField {...params} sx={{p: 0}} variant={'standard'} label='Repair Type' />);
                                }}
                            />
                        </Grid>
                        {/* PARTS */}
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Autocomplete
                                disablePortal
                                id='select-parts'
                                disableCloseOnSelect
                                options={partsOptions}
                                multiple
                                limitTags={2}
                                value={newJob.state.service.parts}
                                // @ts-ignore
                                onChange={ (event, newValue) => { newJob.DISPATCH({type: 'SET_PARTS', payload: {parts: newValue, numberOfServices: (newValue.length || 1)}}); } }
                                isOptionEqualToValue={(option, value) => { return (option.part == value.part && option.position == value.position); }}
                                getOptionLabel={(option) => `${(option.position == 'N/A' || !option.position) ? '' : option.position} ${(option.part == 'N/A' || !option.part) ? '' : option.part}`}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography>{`${(option.position == 'N/A' || !option.position) ? '' : option.position} ${(option.part == 'N/A' || !option.part) ? '' : option.part}`}</Typography>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 10 }}
                                                checked={selected}
                                            />
                                        </Grid>
                                    </li>
                                )}
                                sx={{
                                    mt: 1,
                                    borderRadius: 0, 
                                    border: 0,
                                    borderBottomColor: theme.palette.grey[900],
                                    borderBottom: '1px solid',
                                    width: '88%',
                                }}
                                renderInput={(params) => {
                                    params.InputProps = {...params.InputProps,
                                        style: { fontSize: '14px' },
                                    };
                                    params.InputLabelProps = {...params.InputLabelProps,
                                        style: {fontSize: '14px', color: theme.palette.grey[800] },
                                    };
                                    return (<TextField {...params} sx={{p: 0}} variant={'standard'} label='Part(s)' />);
                                }}
                            />
                        </Grid>
                        <Divider variant='middle' />
                        <Grid item display={'flex'} justifyContent={'center'} p={1} m={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16}}>
                                          Add Note
                            </Typography>
                        </Grid>
                        <Grid item display='flex' alignItems={'center'} justifyContent={'space-between'} width={'88%'} p={1} m={1} ml={4}>
                            <TextField
                                value={newJob.state.service.note}
                                placeholder={'Type note here...'}
                                variant='standard'
                                id='set-note'
                                multiline={true}
                                inputProps={{style: {fontSize: '16px', textTransform: 'none', marginLeft: '8px' }}}
                                sx={{display: 'flex', width: '75%', fontSize: '16px', backgroundColor: 'rgba(255,255,255,.9)'}}
                                InputProps={{disableUnderline: true}}
                                // @ts-ignore
                                onChange={(event) => { newJob.DISPATCH({type: 'SET_NOTE', payload: event.target.value}); } }
                            />
                            <MediaInputButton/>
                            
                        </Grid>
                        <Grid item display='flex' width={'88%'} p={1} m={1} ml={4} sx={{overflow: 'auto'}}>
                            { (newJob.state.service.media && newJob.state.service.media.length > 0) && (
                                showThumbnails()
                            )}
                        </Grid>
                        <Divider variant='middle' />
                    </Paper>
                )}
                { view.repairer && (
                    <Paper onClick={() => {setView({service: true, repairer: false});}} sx={{p:2, mx: 8, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)'}}>
                        <Grid item display={'flex'} justifyContent={'space-between'} my={2} alignItems={'center'}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16}}>
                                            Service(s)
                            </Typography>
                            <Box>
                                <Typography sx={{ color: theme.palette.grey[900], fontSize: 14, textAlign: 'right'}}>
                                    { `${newJob.state.service.type} - ${newJob.state.service.repair}` }
                                </Typography>
                                <Typography sx={{ color: theme.palette.grey[700], fontSize: 12, textAlign: 'right'}}>
                                    { `${newJob.state.service.numberOfServices} Part(s)` }
                                </Typography>
                            </Box>
                        </Grid>
                    </Paper>
                )}
            </Grid>
            <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                { view.repairer && (
                    <Paper sx={{p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%'}}>
                        <Grid item display={'flex'} justifyContent={'center'} my={2}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16}}>
                                Select Vendor
                            </Typography>
                        </Grid>
                        <Grid item my={1} display={'flex'} justifyContent={'center'}>
                            <FormControl sx={{width: '90%'}}>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='1'
                                    name='radio-buttons-group'
                                >   
                                    <Grid item display={'flex'} justifyContent={'space-between'} my={2} alignItems={'center'}>
                                        <FormControlLabel value='1' control={<Radio />} label='My Vendors' />
                                        <Autocomplete
                                            id='select-vendor'
                                            disablePortal
                                            options={vendorOptions}
                                            getOptionLabel={(option) => option.name || ''}
                                            sx={{
                                                width: '50%',
                                                borderRadius: 18, 
                                                border: 0,
                                            }}
                                            value={newJob.state.service.repairer}
                                            isOptionEqualToValue={(option, value) => { return (option.id && option.id == value.id); }}
                                            onChange={ (event, newValue) => { newJob.DISPATCH({type: 'SET_REPAIRER', payload: newValue}); } }
                                            ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                            freeSolo={false}
                                            renderInput={(params) => {
                                                params.InputProps = {...params.InputProps,
                                                    style: { fontSize: '16px' },
                                                };
                                                params.InputLabelProps = {...params.InputLabelProps,
                                                    style: {fontSize: '16px', color: theme.palette.grey[900] },
                                                };
                                                return (
                                                    <TextField {...params} variant={'standard'} label='Select Vendor' />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Divider />
                                    <Grid item display={'flex'} justifyContent={'space-between'} mt={1} alignItems={'center'}>
                                        <FormControlLabel value='5' control={<Radio />} label='The Marketplace' disabled={true}/>
                                        <Typography sx={{ p: 1, mt: 1, width: '50%', color: (false) ? theme.palette.grey[900] : theme.palette.grey[500], fontSize: 16}}>
                                                        Repair on Demand
                                        </Typography>
                                    </Grid>
                                    <Divider/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container my={1} display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16, mt: 2}}>
                                            Set Price
                            </Typography>
                            <Typography sx={{ color: theme.palette.grey[700], fontSize: 14, mb: 2}}>
                                            Price will be applied to each location/part of service
                            </Typography>
                            <Grid item sx={{width: '90%'}} my={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography sx={{ m: 1, p:1, color: theme.palette.grey[900], fontSize: 16}}>
                                    {`Price x ${newJob.state.service.numberOfServices}`}
                                </Typography>
                                <FormControl sx={{ m: 1, p: 1}} variant={'standard'}>
                                    <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13}} htmlFor={'set-price'}>
                                                    Price
                                    </InputLabel>
                                    <Input
                                        id='set-price'
                                        value={newJob.state.service.price}
                                        // @ts-ignore
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                {currencySymbol}
                                            </InputAdornment>
                                        }
                                        error={/^\d*$/.test(newJob.state.service.price)}
                                        inputProps={{style: { border: 0, fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                                        sx={{border: 0, borderBottomColor: theme.palette.grey[900]}}
                                        onChange={(event) => {
                                            // @ts-ignore
                                            newJob.DISPATCH({type: 'SET_PRICE', payload: {price: event.target.value.replace(/[^0-9.]/, ''), currency: 'USD'}});
                                        } }
                                        onBlur={() => {
                                            let t = newJob.state.service.price;
                                            if (!t.includes('.')) t = `${t}.00`;
                                            // @ts-ignore
                                            newJob.DISPATCH({type: 'SET_PRICE', payload: {price: t.replace(/[^0-9.]/, ''), currency: 'USD'}});
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Divider sx={{width: '90%'}}/>
                            <Grid item sx={{width: '90%'}} my={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography sx={{ m:1, p:1, color: theme.palette.grey[900], fontSize: 16}}>
                                                Total
                                </Typography>
                                <Typography sx={{ m:1, p:1, color: theme.palette.grey[900], fontSize: 16}}>
                                    {newJob.state.service.totalDP}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                { view.service && (
                    <Paper onClick={() => {setView({service: false, repairer: true});}} sx={{p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%'}}>
                        <Grid item display={'flex'} justifyContent={'end'} alignItems={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16}}>
                                Select Vendor
                            </Typography>
                            <KeyboardArrowUpIcon sx={{ml: '45%', color: theme.palette.grey.main}}/>
                        </Grid>
                    </Paper>
                )}
            </Grid>
        </>
    );
};

ModalForm.propTypes = {};

export default ModalForm;