import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { Checkbox, Typography } from '@mui/material';
import { CheckBoxOutlineBlankOutlined, Search } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: '12px',
    },
    [`& .${autocompleteClasses.listbox}`]: {
        padding: 0,
        [`& .${autocompleteClasses.option}:last-of-type`]: {
            borderBottom: 'none',
        },
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: '8px',
            borderBottom: `1px solid  ${theme.palette.grey[500]}`,
            width: '100%',
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    boxShadow: theme.shadows[3],
    borderRadius: '8px',
    padding: '8px',
    zIndex: theme.zIndex.modal,
    fontSize: '12px',
    backgroundColor: 'white',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: '8px',
    width: '100%',
}));

const Button = styled(ButtonBase)(({ theme }) => ({
    fontSize: '12px',
    width: '100%',
    textAlign: 'left',
}));

export default function DropDown({label, icon, options, initial = null, multi = true, state, dispatch}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    React.useEffect(() => {
        if (initial !== null) setValue([options[initial]]);
    }, []);

    React.useEffect(() => {
        if (!label) label = value[0];
    }, [value]);

    const handleClick = (event) => {
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setValue(pendingValue);
        switch (label) {
            case 'Locations':
                dispatch({type: 'select_locations', payload: pendingValue.map((e) => e.id)});
                break;
            case 'Repairers':
                dispatch({type: 'select_repairers', payload: pendingValue.map((e) => e.id)});
                break;
            case 'Status':
                dispatch({type: 'select_status', payload: pendingValue.map((e) => e.id)});
                break;
            case 'Services':
                dispatch({type: 'select_services', payload: pendingValue.map((e) => e.id)});
                break;
            case 'Sort':
                // @ts-ignore
                dispatch({type: 'select_sort', payload: pendingValue.id});
                break;
            default:
                break;
        }
        // if (anchorEl) {
        //     anchorEl.focus();
        // }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `${label}-label` : undefined;

    return (
        <React.Fragment>
            <Box sx={{
                border: '1px solid #CAD2D9',
                borderRadius: '8px',
                padding: '8px',
                paddingRight: '2px',
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '32px',
                fontSize: '12px',
            }}>
                <Button disableRipple aria-describedby={id} onClick={handleClick}>
                    {icon || ''}
                    <Typography sx={{ fontSize: '12px', color: theme.palette.grey[900], marginRight: '8px'}} variant='subtitle2'>
                        {label || ''}
                    </Typography>
                    <Typography sx={{ maxWidth: '30px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', color: theme.palette.grey[800]}}  variant='subtitle2'>
                        {(value && value.length) ? `+${value.length}` : ''}
                    </Typography>
                    <ArrowDropDownIcon sx={{color: 'black', marginLeft: '8px'}} />
                </Button>
            </Box>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Autocomplete
                            open
                            sx={{display: 'none'}}
                            multiple={multi}
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setPendingValue(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText='No options'
                            renderOption={(props, option, { selected }) => {return (
                                <li {...props}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankOutlined sx={{opacity: 1, color: theme.palette.blue.main}} />}
                                            checkedIcon={<CheckBoxIcon sx={{opacity: 1, color: theme.palette.blue.main}} />}
                                            style={{ marginRight: '8px' }}
                                            checked={selected}
                                        />
                                        <Typography variant='subtitle2' sx={{fontSize: '12px', textTransform: 'capitalize'}}>
                                            {option.name}
                                        </Typography>
                                    </Box>
                                </li>
                            );}}
                            options={options}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <StyledInput
                                    startAdornment={<Search />}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    placeholder='Search'
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
}

DropDown.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.node,
    options: PropTypes.array,
    initial: PropTypes.number,
    multi: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
};