import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';
import axios from 'axios';

export const getJobs = async function(params) {
    let config = params;
    const result = await apiRodx['get'](`jobs${config.requestParams}`);
    return result;
};

export const getJobCount = async function(params) {
    let config = params;
    const result = await apiRodx['get']('job/count');
    return result;
};

export const getJobById = async function(id) {
    let config = {};
    const result = await apiRodx['get'](`job/${id}`); 
    return result;
};

export const createJob = async function(params) {
    let config = {};
    const result = await apiRodx['post']('job', params.data); 
    return result;
};

export const updateJob = async function(params) {
    let config = {};
    const result = await apiRodx['patch'](`job/${params.id}`, params.data); 
    return result;
};
