// @ts-nocheck
// React
import React, { useEffect } from 'react';
import { 
    useNavigate,
} from 'react-router-dom';
// Local
import { NewJobProvider, useNewJob } from 'services/reducers/newJob';
import NewJobCart from './content/NewJobCart';
import NewJobForm from './content/NewJobForm';
// Styles
import {
    Button,
    Grid,
    useTheme,
} from '@mui/material';

const NewJobScreen = () => {
    const theme = useTheme();
    const newJob = useNewJob();
    const navigate = useNavigate();

    const handleBackClick = async function() {
        // @ts-ignore
        await newJob.DISPATCH({ type: 'RESET_SERVICES' });
        await newJob.DISPATCH({ type: 'RESET_JOB' });
        navigate(-1);
    };

    useEffect(() => {
    }, [newJob]);

    return (
        <>
            <Button
                onClick={handleBackClick}
                variant='filled'
                sx={{backgroundColor: theme.palette.blue.main, color: theme.palette.white, '&:hover': {backgroundColor: theme.palette.blue.main, color: theme.palette.white} }}
            > Back </Button>
            <Grid item display='flex' justifyContent={'space-evenly'}>
                <NewJobForm />
                <NewJobCart />
            </Grid>
        </>
    );
};

export default NewJobScreen;
