/* eslint-disable camelcase */
// React
import React, { useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Local
import DatePicker from './content/DatePicker';
import DropDown from './content/DropDown';
import JobList from './content/JobList';
import JobDetails from './content/JobDetails';
// import Search from './content/Search';
import { ReactComponent as Repairer } from '../../assets/images/repairer.svg';
import { ReactComponent as Services } from '../../assets/images/services.svg';
import { ReactComponent as LoadingCricle } from '../../assets/images/loading_circle_segmented.svg';
import { JobReducer, INIT, INITIAL_STATE } from 'services/reducers/job';
import { getJobs } from '../../services/actions/job';
import { useError } from 'contexts/ErrorContext';
// Styles
import {
    Box,
    Button,
    IconButton,
    Grid,
    Paper,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import {
    ControlPoint as ControlPointIcon,
    InfoOutlined,
    Place,
    Search as SearchIcon,
    SortOutlined,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import './styles.css';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const NewJobButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    background: theme.palette.blue.main,
    color: 'white',
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
}));

const JobsScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const err = useError();
    const [state, dispatch] = useReducer(JobReducer, INITIAL_STATE, INIT);
    const [jobsLoaded, setJobsLoaded] = useState(false);
    const [timeReload, resetTimeReload] = useState(false);

    const getAllJobs = async function() {
        try {
            const data = await getJobs({requestParams: state.filterParams});
            if (data && data.data.status === 200 && data.data.payload.length) {
                // @ts-ignore
                dispatch({type: 'get_jobs', payload: data.data.payload.filter(el => el).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))});
                // @ts-ignore
                err.DISPATCH({type: 'NO_ERROR', payload: {alert: 'Found the Jobs!', status: data.data.status}});
                setJobsLoaded(true);
                resetTimeReload(false);
            }
        } catch (error) {
            // @ts-ignore
            console.log(error);
            err.DISPATCH({type: 'API_ERROR', payload: error});
        }
    };
    const pills = ['All Work', 'Open Work', 'Closed Work'];
    const [currentPill, setCurrentPill] = useState(pills[0]);
    const handlePillClick = function (pill) {
        setCurrentPill(pill);
        dispatch({type: 'set_filter_params', payload: ''});
    };
    const pillBar = function () {
        return pills.map((pill, i) => {
            return (<Grid item key={i}> 
                <Button
                    onClick={() => handlePillClick(pill)}
                    sx={{ p: 0.5, m: 0.5, fontSize: '16px', textTransform: 'none',
                        color: (pill === currentPill) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(pill === currentPill) ? 'active': ''}
                > { pill } </Button>
            </Grid>);
        });
    };

    const [currentQT, setCurrentQT] = useState('All Work');
    const handleQTClick = function (qt) {
        setCurrentQT(qt);
        const statusMap = {
            'Pending': ['Open', 'Pending Approval'],
            'Accepted': ['Accepted'],
            'In Progress': ['In Progress'],
        };
        // @ts-ignore
        dispatch({ type: 'set_filter_params', payload: { 'service_statuses': statusMap[qt] } });
    };
    const quickTabBar = function (current) {
        const quickTabs = {
            'All Work': [],
            'Open Work': ['All', 'Pending', 'Accepted', 'In Progress'],
            'Closed Work': ['All', 'Completed', 'Cancelled'],
        };
        return quickTabs[current].map((q, i) => {
            return (<Grid item key={i}> 
                <Button
                    variant={'contained'}
                    onClick={() => handleQTClick(q)}
                    sx={{ py: .5, px: 3, m: 1, fontSize: '16px', textTransform: 'none', fontWeight: 400,
                        color: (q === currentQT) ? theme.palette.white : theme.palette.black,
                        backgroundColor: (q === currentQT) ? theme.palette.blue[800] : theme.palette.grey[50],
                        '&:hover': {
                            color: theme.palette.blue[500],
                            backgroundColor: theme.palette.blue[800],
                        },
                    }}
                    // className={(q === currentQT) ? 'active': ''}
                > { q } </Button>
            </Grid>);
        });
    };

    useEffect(() => {
        if (resetTimeReload || !jobsLoaded) {
            if (resetTimeReload || (!state.jobs || (state.jobs && !state.jobs.length))) {
                getAllJobs().catch(console.error);
            }
        }
        // TODO Finish and enable auto repull
        // if (!timeReload) {
        //     setTimeout(() => {
        //         resetTimeReload(true);
        //     }, 1200000); // 20 minute reload data
        // }
    }, [jobsLoaded, timeReload]);

    return (
        <Grid container display={'flex'}>
            <Grid item container display={'flex'} justifyContent={'space-between'}>
                <Grid item mb={2}>
                    <Typography variant='h1' display={'flex'} alignItems={'center'}> { 'Jobs' } </Typography>
                </Grid>
                <Grid item container display={'flex'} justifyContent={'space-between'} mb={2} alignItems={'center'}>
                    <Box display={'flex'} >
                        { pillBar() }
                    </Box>
                    <NewJobButton
                        variant='contained'
                        onClick={() => navigate('/new_job')}
                        startIcon={<ControlPointIcon />}
                    >
                        New Job
                    </NewJobButton>
                </Grid>
            </Grid>
            <Grid item container display={'flex'} >
                <ContentContainer sx={{p:0, mb: 6}}  >
                    { currentPill != 'Cancelled' && 
                        <Grid display={'flex'}>
                            { quickTabBar(currentPill) }
                        </Grid>
                    }
                    <Grid item alignContent={'center'} justifyContent={'space-between'} alignItems={'center'} p={1} display={'flex'} sx={{borderTop: '1px solid', borderBottom: '1px solid', borderColor: theme.palette.grey[50]}}>
                        <Grid display={'flex'} gap={1}>
                            {/* <Search dispatch={dispatch} state={state} disabled={false}/> */}
                            <DropDown
                                label={'Sort'}
                                dispatch={dispatch} state={state}
                                multi={false}
                                icon={<SortOutlined style={{ marginRight: '5px' }} width={'20px'} height={'20px'} sx={{ color: theme.palette.blue.main }} />}
                                options={state.sort.map((el) => {
                                    return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                })}
                            />
                        </Grid>
                        <Grid display={'flex'} gap={1} alignItems={'center'}>
                            <DatePicker dispatch={dispatch} state={state} />
                            <DropDown
                                label={'Locations'}
                                dispatch={dispatch} state={state}
                                icon={<Place style={{ marginRight: '5px' }} width={'20px'} height={'20px'} sx={{ color: theme.palette.blue.main }} />}
                                options={state.locations.map((el) => {
                                    return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                })}
                            />
                            { state.selectedLocations.length > 0 && (
                                <DropDown
                                    label={'Repairers'}
                                    dispatch={dispatch} state={state}
                                    icon={<Repairer style={{ marginRight: '5px' }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                    options={state.repairers.map((el) => {
                                        return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                    })}
                                />
                            )}
                            <DropDown
                                label={'Supplements'}
                                dispatch={dispatch} state={state}
                                icon={<Services style={{ marginRight: '5px' }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                options={state.supplements.map((el) => {
                                    return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                })}
                            />
                            <DropDown
                                label={'Services'}
                                dispatch={dispatch} state={state}
                                icon={<Services style={{ marginRight: '5px' }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />}
                                options={state.services.map((el) => {
                                    return {...el, name: el.name.replace('_',' ').toLowerCase()};
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{p: 0, mx: 0}} display={'flex'} minHeight={'800px'} maxHeight={'800px'}>
                        <Grid item xs={3} sx={{borderRight: '1px solid', borderColor: theme.palette.grey[50]}} overflow={'auto'}>
                            <Paper elevation={0} sx={{backgroundColor: theme.palette.background.paper}}>
                                <Box sx={{ height: '50px', width: '100%'}} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                                    { (!jobsLoaded) && (
                                        <LoadingCricle width={'100%'}/>
                                    )}
                                    { (jobsLoaded ) && (
                                        <>
                                            <Typography p={1} m={1} sx={{ fontWeight: 500 }}> {`${state.jobs.length} Vehicles`} </Typography>
                                            <IconButton onClick={() => getAllJobs().catch(console.error) }> <RefreshIcon /> </IconButton>
                                        </>
                                    )}
                                </Box>
                                { jobsLoaded && (<JobList dispatch={dispatch} state={state} />) }
                            </Paper>
                        </Grid>
                        <Grid item xs={9} display={'flex'} justifyContent={'space-between'} overflow={'auto'}>
                            {(jobsLoaded ) && (
                                <JobDetails state={state} dispatch={dispatch} />
                            )}
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Grid>
            
        </Grid>
    );
};

export default JobsScreen;
