import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import avatar from 'assets/images/avatar.svg';

import { AppBar, Avatar, Box, Button, Card, CardContent, Fade, Grid, Popover, Typography, useTheme } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { Outlet, useNavigate } from 'react-router-dom';
import AppSearch from './search';
import HeaderIcons from './HeaderIcons';

const UserDetails = function () {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(!isOpen);
    };
    const id = isOpen ? 'userDetails-popover' : undefined;

    return (
        <Box display='flex' alignItems={'center'} gap={2}>
            <Grid
                container
                spacing={2}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                onClick={handleClick}
            >
                <Grid item>
                    <Typography
                        variant={'subtitle2'}
                        alignItems={'center'}
                    >
                        {`${auth.firstName} ${auth.lastName}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar src={avatar}></Avatar>
                </Grid>
            </Grid>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{ sx: { borderRadius: '16px', boxShadow: '0px 12px 32px -8px rgba(42, 42, 42, 0.24)' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Fade in={isOpen}>
                    <Card>
                        <CardContent>
                            <Button
                                type='button'
                                color='secondary'
                                variant='contained'
                                onClick={() => { 
                                    auth.logout().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                sx={{
                                    borderRadius: '100px',
                                    padding: '8px 40px',
                                    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                                    fontWeight: '600',
                                    fontSize: '14px',
                                }}
                            >
                                logout
                            </Button>
                        </CardContent>
                    </Card>
                </Fade>
            </Popover>
        </Box >
    );
};

const Header = () => {
    const theme = useTheme();
    const logo = useMemo(() => {
        return theme.palette.mode === 'dark'
            ? 'https://uploads-ssl.webflow.com/62f66165d6557d71f09a2225/62f663bce221ec8063ce2ab8_RepairOnDemand_Fullcjolour_RGB-p-500.png'
            : 'https://uploads-ssl.webflow.com/62f66165d6557d71f09a2225/6324973c73b5d3ad722db2c7_Repair%20OnDemand%20Logo%20-%20Black-p-500.png';
    }, [theme.palette.mode]);
    return (
        <>
            <AppBar position='fixed'>
                <Box display='flex' justifyContent='space-between' p='10px 40px' alignItems='center'>
                    <Box flex={1}>
                        <img alt='Repair OnDemand' style={{ maxWidth: 185 }} src={logo} />
                    </Box>
                    <Box flex={1}>
                        {/* <AppSearch /> */}
                    </Box>
                    <Box flex={1} sx={{display: 'flex', justifyContent: 'end'}}>
                        <HeaderIcons />
                    </Box>
                </Box>
            </AppBar>
        </>
    );
};

Header.propTypes = {
  
};

export default Header;