import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const INIT = function () {
    return INITIAL_STATE;
};

export const INITIAL_STATE = {
    locations: [],
    location: {},
    activeLocation: 0,
    editing: false,
    newLocation: {
        'nickname': '',
        'street_number': '',
        'street_name': '',
        'subpremise': '',
        'city': '',
        'state': '',
        'country': '',
        'zip': '',
        'contact_info': {
            'name': '',
            'email': '',
            'phone_number': '',
        },
    },
};

export const LocationReducer = function (state, action) {
    console.log(JSON.stringify(action, null, 2));
    switch (action.type) {
        case 'INIT':
            return { ...INITIAL_STATE };
        case 'GET_LOCATIONS':
            return { ...state, locations: action.payload };
        case 'GET_LOCATION':
            return { ...state, location: action.payload };
        case 'SET_ACTIVE_LOCATION':
            return { ...state, activeLocation: action.payload };
        case 'SET_NEW_LOCATION_DATA':
            return { ...state, newLocation: { ...state.newLocation, ...action.payload } };
        case 'START_STOP_EDITING':
            return { ...state, editing: !state.editing };
        default:
            return {...state};
    }
};

export const LocationContext = createContext({});

export const LocationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(LocationReducer, INITIAL_STATE, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <LocationContext.Provider value={value}> { children } </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    children: PropTypes.node,
};

export const useLocations = () => useContext(LocationContext);
