import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';
import axios from 'axios';

export const attachMediaToService = async function(params) {
    let config = params;
    const result = await apiRodx['post'](`service/${config.serviceID}/attach-media`, config.data);
    return result;
};

export const updateService = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`service/${config.serviceID}`, config.data); 
    return result;
};

export const cancelService = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`service/${config.serviceID}`, {'service_status': 'Cancelled'}); 
    return result;
};

export const approveSupplementService = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`service/${config.serviceID}`, {'service_status': 'Accepted'}); 
    return result;
};

export const declineSupplementService = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`service/${config.serviceID}`, {'service_status': 'Declined', 'price': config.price}); 
    return result;
};

export const requestReworkService = async function(params) {
    let config = params;
    const result = await apiRodx['patch'](`service/${config.serviceID}`, {'service_status': 'Open'}); 
    return result;
};