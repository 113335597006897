// React
import React, { useEffect, useState } from 'react';
// Local
import { useError } from 'contexts/ErrorContext';
import LocationList from './content/LocationList';
import LocationDetails from './content/LocationDetails.jsx';
import { LocationProvider, useLocations } from 'services/reducers/location';
import { getLocations } from '../../services/actions/location';
import { ReactComponent as LoadingCricle } from '../../assets/images/loading_circle_segmented.svg';
import { ReactComponent as RepairerIcon } from '../../assets/images/repairer.svg';
import { ReactComponent as StatusInfoIcon } from  '../..//assets/images/status_info_icon.svg';
// Styles
import { Box, styled, Grid, Typography, useTheme, Button, Autocomplete, TextField, Checkbox, Paper, DialogContent, Divider, Modal, Fade, Backdrop, FormControl, InputLabel, Input } from '@mui/material';
import { AddLocationAlt } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ModalForm from 'screens/NewJobScreen/content/ModalForm';
import { LocationModal } from './content/newLocation';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0),
    width: '100%',
}));

const NewButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    borderRadius: 8,
    background: theme.palette.blue.main,
    color: theme.palette.white,
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
    '&: hover': {backgroundColor: theme.palette.blue.main},
    variant: 'contained',
}));

const LocationsScreen = () => {
    const theme = useTheme();
    const err = useError();
    const LOCATION = useLocations();
    const [locationsLoaded, setLocationsLoaded] = useState(false);
    const [timeReload, resetTimeReload] = useState(false);
    const [open, setOpen] = useState(false);

    const getAllLocations = async function() {
        try {
            const data = await getLocations();
            if (data && data.data.status === 200 && data.data.payload.length) {
                console.log(LOCATION);
                // @ts-ignore
                LOCATION.DISPATCH({type: 'GET_LOCATIONS', payload: data.data.payload.filter(el => el).sort((a, b) => a.nickname - b.nickname)});
                // @ts-ignore
                err.DISPATCH({type: 'NO_ERROR', payload: {alert: 'Found the Locations!', status: data.data.status}});
                setLocationsLoaded(true);
                resetTimeReload(false);
            }
        } catch (error) {
            // @ts-ignore
            console.log(error);
            err.DISPATCH({type: 'API_ERROR', payload: error});
        }
    };

    const newButton = function() {
        return  (
            <NewButton
                onClick={() => setOpen(true)}
                startIcon={<AddLocationAlt/>}
            >
                Add Location
            </NewButton>
        );
    };

    const formInput = function(formType, id, onChange, label, stateVar, options, getOptionLabel, disabled) {
        if (formType === 0) {
            return (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <Autocomplete
                            id={id}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            sx={{
                                pl: 1,
                                borderRadius: 0, 
                                border: 0,
                                borderBottomColor: theme.palette.grey[900],
                                borderBottom: '1px solid',
                            }}
                            ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                            freeSolo={false}
                            disabled={disabled}
                            autoComplete={true}
                            onChange={onChange}
                            renderInput={(params) => {
                                params.InputProps = {...params.InputProps,
                                    style: { fontSize: '13px' },
                                };
                                params.InputLabelProps = {...params.InputLabelProps,
                                    style: {fontSize: '13px', color: theme.palette.grey.main },
                                };
                                return (
                                    <TextField {...params} variant={'standard'} label={label} sx={{p: 0}} />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        } else {
            return  (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={id}>
                            { label }
                        </InputLabel>
                        <Input
                            id={id}
                            inputProps={{style: { fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                            sx={{width: '100%', border: '0'}}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    useEffect(() => {
        if (resetTimeReload || !locationsLoaded) {
            if (resetTimeReload || (!LOCATION.state.locations || (LOCATION.state.locations && !LOCATION.state.locations.length))) {
                getAllLocations().catch(console.error);
            }
        }
        // TODO Finish and enable auto repull
        // if (!timeReload) {
        //     setTimeout(() => {
        //         resetTimeReload(true);
        //     }, 1200000); // 20 minute reload data
        // }
    }, [locationsLoaded, timeReload]);

    return (
        <Grid container display={'flex'} direction={'column'}>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 300,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        backgroundColor: theme.palette.grey[30],
                        border: '0',
                        borderRadius: '16px',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                        p: 1,
                    }}>
                        <Grid item>
                            <Grid item display={'flex'} justifyContent={'center'} my={3}>
                                <Typography sx={{ color: theme.palette.grey[900], fontSize: 20}}>
                    Add New Location
                                </Typography>
                            </Grid>
                            <LocationModal/>
                            <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                                <Button
                                    onClick={() => { LOCATION.DISPATCH({type: 'START_STOP_EDITING'}); setOpen(false); }}
                                    variant='contained'
                                    sx={{backgroundColor: theme.palette.blue[700], color: theme.palette.white, p: 2, width: '65%', textTransform: 'none'}}
                                >
                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={'h1'} m={1} p={1}> Locations </Typography>
                { newButton() }
            </Grid>
            <Grid container display={'flex'}>
                <ContentContainer>
                    <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        {/* QUICK FIND */}
                        <Grid item display={'flex'} p={1} mx={1} xs={3}>
                            {/* <Autocomplete
                                id='quick-find'
                                options={['this', 'that', 'the other']}
                                sx={{
                                    m: 1,
                                    borderRadius: 0, 
                                    border: 0,
                                    borderBottomColor: theme.palette.grey[900],
                                    borderBottom: '1px solid',
                                    width: '100%',
                                }}
                                ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                renderInput={(params) => {
                                    params.InputProps = {...params.InputProps,
                                        style: { fontSize: '14px' },
                                    };
                                    params.InputLabelProps = {...params.InputLabelProps,
                                        style: {fontSize: '14px', color: theme.palette.grey[800] },
                                    };
                                    return (<TextField {...params} sx={{p: 0}} variant={'standard'} label='Quick find' />);
                                }}
                            /> */}
                        </Grid>
                        <Grid item display={'flex'} p={1} mx={1} xs={7} alignItems={'center'}>
                            {/* Repairers */}
                            <Grid  display={'flex'} alignItems={'center'} sx={{width: '150%', borderRadius: 2, border: 1.5, borderColor: theme.palette.grey[100], px: 0, mx: 1}}>
                                <RepairerIcon style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />
                                <Autocomplete
                                    id='repairers'
                                    options={['this', 'that', 'the other']}
                                    sx={{
                                        border: 0,
                                        width: '100%',
                                    }}
                                    disableCloseOnSelect
                                    ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                    multiple
                                    limitTags={1}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography>{ option }</Typography>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 10 }}
                                                    checked={selected}
                                                />
                                            </Grid>
                                        </li>
                                    )}
                                    renderTags={(tagValue) => {
                                        return tagValue.filter(el => el != 'Repairers').map((option, index) => {
                                            return (
                                                <Typography key={index}> {option} &nbsp; </Typography>
                                            );});
                                    }}
                                    renderInput={(params) => {
                                        params.InputProps = {...params.InputProps,
                                            style: { padding: 0, fontSize: 14},
                                        };
                                        params.InputLabelProps = {...params.InputLabelProps,
                                            style: {backgroundColor: 'white', width: '100%', margin: 0, fontSize: 16, fontWeight: 500, color: theme.palette.grey[800], padding: 0, borderRadius: 4},
                                        };
                                        return (
                                            <TextField {...params} placeholder={'Repairers'} sx={{p: .5, input: {
                                                '&::placeholder': {
                                                    fontSize: 14, fontWeight: 500, color: theme.palette.grey[900], opacity: 1,
                                                }}}}/>
                                        );
                                    }}
                                />
                            </Grid>
                            {/* Status */}
                            <Grid  display={'flex'} alignItems={'center'} sx={{width: '110%', borderRadius: 2, border: 1.5, borderColor: theme.palette.grey[100], px: 0, mx: 1}}>
                                <StatusInfoIcon style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />
                                <Autocomplete
                                    id='status'
                                    options={['this', 'that', 'the other']}
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        border: 0,
                                        width: '100%',
                                    }}
                                    disableCloseOnSelect
                                    ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                    multiple
                                    limitTags={1}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography>{ option }</Typography>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 10 }}
                                                    checked={selected}
                                                />
                                            </Grid>
                                        </li>
                                    )}
                                    renderTags={(tagValue) => {
                                        return tagValue.map((option, index) => {
                                            return (
                                                <Typography key={index}> {option} &nbsp; </Typography>
                                            );});
                                    }}
                                    renderInput={(params) => {
                                        params.InputProps = {...params.InputProps,
                                            style: { padding: 0, fontSize: 14},
                                        };
                                        params.InputLabelProps = {...params.InputLabelProps,
                                            style: {backgroundColor: 'white', width: '100%', margin: 0, fontSize: 16, fontWeight: 500, color: theme.palette.grey[800], padding: 0, borderRadius: 4},
                                        };
                                        return (
                                            <TextField {...params} placeholder={'Status'} sx={{p: .5, input: {
                                                '&::placeholder': {
                                                    fontSize: 14, fontWeight: 500, color: theme.palette.grey[900], opacity: 1,
                                                }}}}/>
                                        );
                                    }}
                                />
                            </Grid>
                            {/* Invoice Type */}
                            <Grid  display={'flex'} alignItems={'center'} sx={{width: '130%', borderRadius: 2, border: 1.5, borderColor: theme.palette.grey[100], px: 0, mx: 1}}>
                                <StatusInfoIcon style={{ margin: 4 }} width={'20px'} height={'20px'} fill={theme.palette.blue.main} />
                                <Autocomplete
                                    id='invoice-type'
                                    options={['this', 'that', 'the other']}
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        border: 0,
                                        width: '100%',
                                    }}
                                    disableCloseOnSelect
                                    ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                                    multiple
                                    limitTags={1}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography>{ option }</Typography>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 10 }}
                                                    checked={selected}
                                                />
                                            </Grid>
                                        </li>
                                    )}
                                    renderTags={(tagValue) => {
                                        return tagValue.map((option, index) => {
                                            return (
                                                <Typography key={index}> {option} &nbsp; </Typography>
                                            );});
                                    }}
                                    renderInput={(params) => {
                                        params.InputProps = {...params.InputProps,
                                            style: { padding: 0, fontSize: 14},
                                        };
                                        params.InputLabelProps = {...params.InputLabelProps,
                                            style: {backgroundColor: 'white', width: '100%', margin: 0, fontSize: 20, fontWeight: 500, color: theme.palette.grey[800], padding: 0, borderRadius: 4},
                                        };
                                        return (
                                            <TextField {...params} placeholder={'Invoice type'} sx={{p: .5, input: {
                                                '&::placeholder': {
                                                    fontSize: 14, fontWeight: 500, color: theme.palette.grey[900], opacity: 1,
                                                }},
                                            }}/>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{color: theme.palette.grey[50], width: '100%'}}/>
                    <Grid display={'flex'} sx={{p: 0, mx: 0}} minHeight={'800px'} maxHeight={'800px'}>
                        <Grid xs={3} display={'flex'} sx={{borderRight: '1px solid', borderColor: theme.palette.grey[50]}} overflow={'auto'}>
                            <Paper elevation={0} sx={{ backgroundColor: theme.palette.background.default }}>
                                <Box sx={{ height: '50px', width: '100%', backgroundColor: theme.palette.background.paper }} alignItems={'center'} display={'flex'}>
                                    { (!locationsLoaded) && (
                                        <LoadingCricle width={'100%'}/>
                                    )}
                                    { (locationsLoaded) && (
                                        <Typography p={1} m={1} sx={{fontWeight: 500}}> {`${LOCATION.state.locations.length} Locations`} </Typography>
                                    )}
                                </Box>
                                <Divider sx={{width: '100%', mb: 1}}/>
                                { (locationsLoaded && (LOCATION.state.locations.length > 0)) && (<LocationList />) }
                            </Paper>
                        </Grid>
                        <Grid xs={9} display={'flex'} justifyContent={'space-between'} overflow={'auto'}>
                            {(locationsLoaded) && (
                                <LocationDetails />
                            )}
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Grid>
        </Grid >
    );
};

export default LocationsScreen;