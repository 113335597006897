// React
import React, {
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as RepairIcon } from '../../../assets/images/repair_icon.svg';
import { ReactComponent as ServiceIcon } from '../../../assets/images/service_icon.svg';
// Styles
import { Card, Divider, Grid, Typography, useTheme } from '@mui/material';

const JobCard = ({ data, job, setActiveCard, state}) => {
    const theme = useTheme();
    
    const capitalize = (str) =>
        str.toLowerCase().replace(/\b\w/g, (match) =>
            match.replace(/\w/, (m) => m.toUpperCase()));

    const getTimestamp = (() => {
        return `${data.timestamp.slice(0,1).toUpperCase()}${data.timestamp.slice(1)}`;
    });

    const getStatuses = (() => {
        return data.jobStatuses.map((el, i) =>  <Card key={i} variant={el.statusStyle}> { el.status } </Card> );
    });

    const getYYMC = (() => {
        let yymc = [];
        if (data.year || data.make || data.model) yymc.push(`${data.year || ''} ${capitalize(data.make) || ''} ${capitalize(data.model) || ''}`);
        if (data.color) yymc.push(capitalize(data.color));
        return yymc.join(', ');
    });

    const getLocation = (() => {
        return data.location || 'Location Nickname';
    });

    const getRepairers = (() => {
        return data.repairerList;
    });

    const getServices = (() => {
        return data.serviceList;
    });

    const getJobTotal = (() => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: data.currency }).format(data.total);
    });

    const getStock = (() => {
        return `Stock/RO: ${data.stockRO}`;
    });

    const getVin = (() => {
        return `VIN: ${data.vin.toUpperCase()}`;
    });
    
    useEffect(() => {
    }, []);
    
    return (
        <Card
            className={(state.activeJob === job.id) ? 'activeCard' : 'inactiveCard'}
            raised={true}
            onClick={() => { setActiveCard(job.id); }}
            sx={{
                'm': .5,
                'p': 1,
                'borderRadius': '8px',
            }}>
            {/* TODO add a conditional or remove 'TRUE' */}
            { true && (
                <Grid container direction='column' justifyContent='space-between' alignItems='flex-start'>
                    <Grid item xs={2} container justifyContent='space-between' alignItems='flex-start' sx={{mb:.5}}>
                        <Grid item>
                            <div className='dot' id={data.timestamp}/>
                            <Typography className='ts_status' id={data.timestamp} > { getTimestamp() } </Typography>
                            
                        </Grid>
                        <Typography className='ro_total' > { getJobTotal() } </Typography>
                    </Grid>
                    <Grid item container xs={4} direction='column' justifyContent='space-evenly' alignItems='flex-start' sx={{mb:.5}}>
                        <Typography className='yymc'> { getYYMC() } </Typography>
                        <Typography className='details'> { getVin() } </Typography>
                        <Typography className='details'> { getStock() } </Typography>
                        <Typography className='location'> { getLocation() } </Typography>
                    </Grid>
                    <Divider style={{display: 'flex', alignSelf: 'center', width: '95%', marginTop: 4, marginBottom: 4}}/>
                    <Grid item container xs={6} direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                        <Grid item justifyContent={'space-between'} alignItems={'center'} display={'flex'} p={.25}>
                            <ServiceIcon width={18} /> &nbsp;
                            <Typography className='details' > { getServices() } </Typography>
                        </Grid>
                        <Grid item justifyContent={'space-between'} alignItems={'center'} display={'flex'} p={.25}>
                            <RepairIcon width={18} /> &nbsp;
                            <Typography className='details' > { getRepairers() } </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{display: 'flex', alignSelf: 'center', width: '95%', marginTop: 4, marginBottom: 4}}/>
                    <Grid display={'flex'} flexWrap={'wrap'} gap={.5}>
                        { getStatuses() }
                    </Grid>
                </Grid> 
            )}
        </Card>
    );
};

JobCard.propTypes = {
    data: PropTypes.object.isRequired, 
    job: PropTypes.object.isRequired, 
    setActiveCard: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired, 
};

export default JobCard;