// React
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as InvoicesWIP } from '../../assets/images/placeholders/InvoicesWIP.svg';
import { useAuth } from 'contexts/AuthContext';
// Styles
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const InvoicesScreen = () => {
    console.log('Invoices');

    // app context
    const theme = useTheme();

    return (
        <Grid>
            <Typography variant={'h1'} m={1} p={1}> Invoices </Typography>
            <ContentContainer>
                <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
                    <InvoicesWIP width={'100%'} height={'100%'}/>
                </Grid>
            </ContentContainer>
        </Grid >
    );
};

export default InvoicesScreen;