// React
import React, { useCallback, useEffect, useRef, useState } from 'react';
// Local
import { useLocations } from 'services/reducers/location';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// Styles
import './styles.css';
import { Autocomplete, Box, Button, Divider, FormControl, Grid, Input, InputLabel, Paper, Switch, TextField, Typography, alpha, styled, useTheme } from '@mui/material';

export const LocationModal = ()=> {
    const theme = useTheme();
    const LOCATION = useLocations();
    const inputRef = document.getElementById('select-add1-new');
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [editing, setEditing] = useState(false);

    const formInput = function(formType, id, onChange, label, stateVar, options, getOptionLabel, disabled) {
        if (formType === 0) {
            return (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <Autocomplete
                            id={id}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            sx={{
                                pl: 1,
                                borderRadius: 0, 
                                border: 0,
                                borderBottomColor: theme.palette.grey[900],
                                borderBottom: '1px solid',
                            }}
                            ListboxProps={{sx: { fontSize: 14, padding: 1}}}
                            freeSolo={false}
                            disabled={disabled}
                            autoComplete={true}
                            onChange={onChange}
                            renderInput={(params) => {
                                params.InputProps = {...params.InputProps,
                                    style: { fontSize: '13px' },
                                };
                                params.InputLabelProps = {...params.InputLabelProps,
                                    style: {fontSize: '13px', color: theme.palette.grey.main },
                                };
                                return (
                                    <TextField {...params} variant={'standard'} label={label} sx={{p: 0}} />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            );
        } else {
            return  (
                <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                    <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (stateVar) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                        <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={id}>
                            { label }
                        </InputLabel>
                        <Input
                            id={id}
                            inputProps={{style: { fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                            sx={{width: '100%', border: '0'}}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
            );
        }
    };

    const onChangeAddress = (e) => {
        setHasDataChanged(false);
        console.log('value: ', e.target.value);
        setHasDataChanged(true);
    };
    
    useEffect(() => {
        if (!hasDataChanged) return;
        // eslint-disable-next-line no-undef
        const autoComplete = new google.maps.places.Autocomplete(inputRef, {
            // componentRestrictions: { country: ['us', 'ca'] },
            fields: ['address_components', 'geometry', 'name'],
            // types: ['address'],
        });
        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace();
            console.log(place);
            const formattedPlace = {
                'subpremise': place.address_components.filter(el => el.types.includes('subpremise')).map(el => el.long_name)[0] || '',
                'street_number': place.address_components.filter(el => el.types.includes('street_number')).map(el => el.long_name)[0] || '',
                'street_name': place.address_components.filter(el => el.types.includes('route')).map(el => el.long_name)[0] || '',
                'city': place.address_components.filter(el => el.types.includes('locality')).map(el => el.long_name)[0] || '',
                'state': place.address_components.filter(el => el.types.includes('administrative_area_level_1')).map(el => el.long_name)[0] || '',
                'country': place.address_components.filter(el => el.types.includes('country')).map(el => el.long_name)[0] || '',
                'zip': place.address_components.filter(el => el.types.includes('postal_code')).map(el => el.long_name)[0] || '',
            };
            LOCATION.DISPATCH({ type: 'SET_NEW_LOCATION_DATA', payload: formattedPlace });
        });
    }, [inputRef, hasDataChanged]);

    return (
        <Grid container>
            <Grid item xs={2}> </Grid>
            <Grid item xs={8}> 
                <Paper variant={'outlined'} elevation={0} sx={{width: '100%', backgroundColor: theme.palette.white, mb: 4}}>
                    <Grid container flexDirection={'column'} display={'flex'} sx={{ width: '100%', px: 2}} justifyContent={'space-between'} my={1} pb={2}>
                        {/* LOCATION NICKNAME */}
                        { formInput(1,
                            'select-nickname',
                            (event) => {
                                LOCATION.DISPATCH({type: 'SET_NEW_LOCATION_DATA', payload: { 'nickname': event.target.value }});
                            },
                            'Location Name',
                            LOCATION.state.newLocation.nickname)
                        }
                        {/* ADDRESS 1 */}
                        <Grid item display='flex' justifyContent={'center'} p={0.5} my={.25}>
                            <FormControl sx={{ m: 0.5, width: '80%', backgroundColor: (LOCATION.state.add1) ? theme.palette.blue[30] : theme.palette.white, p: 0}} variant={'standard'}>
                                <InputLabel sx={{ml: 1, pt: 0.5, width: '100%', fontSize: 13, color: theme.palette.grey.main}} htmlFor={'select-add1'}>
                                    { 'Address 1' }
                                </InputLabel>
                                <Input
                                    id={'select-add1-new'}
                                    inputProps={{style: { fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' }}}
                                    sx={{ width: '100%', border: '0' }}
                                    onChange={onChangeAddress}
                                    // onChange={(event) => { LOCATION.DISPATCH({type: 'SET_ADDRESS1', payload: event.target.value}); }}
                                />
                            </FormControl>
                        </Grid>
                        {/* ADDRESS 2 */}
                        { formInput(1,
                            'select-add2',
                            (event) => { LOCATION.DISPATCH({type: 'SET_ADDRESS2', payload: event.target.value}); },
                            'Address 2',
                            LOCATION.state.add2)
                        }
                        <Typography textAlign={'center'} mt={1} sx={{fontWeight: 300, color: theme.palette.grey[500]}}> Location Contact Information </Typography>
                        {/* FULL NAME */}
                        { formInput(1,
                            'select-fullname',
                            (event) => { LOCATION.DISPATCH({type: 'SET_FNAME', payload: event.target.value}); },
                            'Contact Name',
                            LOCATION.state.fName)
                        }
                        {/* LAST NAME */}
                        {/* { formInput(1,
                            'select-lname',
                            (event) => { LOCATION.DISPATCH({type: 'SET_LNAME', payload: event.target.value}); },
                            'Last Name',
                            LOCATION.state.lName)
                        } */}
                        {/* EMAIL */}
                        { formInput(1,
                            'select-email',
                            (event) => { LOCATION.DISPATCH({type: 'SET_EMAIL', payload: event.target.value}); },
                            'Email',
                            LOCATION.state.email)
                        }
                        {/* PHONE */}
                        { formInput(1,
                            'select-phone',
                            (event) => { LOCATION.DISPATCH({type: 'SET_PHONE', payload: event.target.value}); },
                            'Phone',
                            LOCATION.state.phone)
                        }
                        {/* BILLING */}
                        { formInput(0,
                            'select-billing',
                            (event, newValue) => { LOCATION.DISPATCH({type: 'SET_BILLING', payload: newValue}); },
                            'Payment Options',
                            LOCATION.state.billing,
                            ['Credit Card 1', 'Bank #12345', 'Credit Card 4'], // billingOptions
                            (option) => option,
                            true)
                        }
                        {/* NOTES */}
                        { formInput(1,
                            'select-notes',
                            (event) => { LOCATION.DISPATCH({type: 'SET_NOTES', payload: event.target.value}); },
                            'Notes',
                            LOCATION.state.notes)
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={2}> </Grid>
        </Grid>
    );
};
