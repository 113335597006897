import { apiRodx } from '../../config';
import { useError } from 'contexts/ErrorContext';

export const getRepairers = async function(params) {
    let config = params;
    const result = await apiRodx['get']('company/connections');
    return result;
};

export const getRepairVendors = async function(params) { // TEMP
    let result = [];
    result = [... result,
        { id: '6f89482b-32d3-11ee-baab-063d2160054b', name: 'asTech' },
        { id: '6f9f4cef-32d3-11ee-baab-063d2160054b', name: 'GreatWater Garages' },
        { id: '6f944ab2-32d3-11ee-baab-063d2160054b', name: 'High Tech Locksmiths' },
    ];
    return result;
};
